import { render, staticRenderFns } from "./ResourceSelect.vue?vue&type=template&id=46675d46&scoped=true&"
import script from "./ResourceSelect.vue?vue&type=script&lang=js&"
export * from "./ResourceSelect.vue?vue&type=script&lang=js&"
import style0 from "./ResourceSelect.vue?vue&type=style&index=0&id=46675d46&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46675d46",
  null
  
)

export default component.exports