<template>
  <div>
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeError="closeError"
      @closeWarning="closeWarning"
    />
    <spinner class="spinner" v-if="loading" />
    <div class="container" v-else>
      <breadcrumps :breadcrumps="[{ name: $tc('worklist.worklist', 1), route: { name: 'clinical-cases' } }, { name: $t('editClinicalCase') }]" />
      <div class="page-header" style="justify-content: space-between">
        <h5>{{ $t("editClinicalCase") }}</h5>
        <!-- TODO Add Upload Button -->
        <label for="uploadCaseInfo" class="btn btn-secondary" style="margin-bottom: 0" v-if="adtImportEnabled">
          {{ $t("uploadCaseInfo") }}
          <input id="uploadCaseInfo" type="file" accept=".xml" @change="uploadCaseInformation()" hidden />
        </label>
      </div>
      <div class="page-body">
        <div class="information-icon-wrapper">
          <div><information-outline-icon class="information-icon" /></div>
          <div>{{ $t("caseRegistrationInformation") }}</div>
        </div>
        <hr />
        <form ref="form" @submit.prevent="onSubmit" autocomplete="off">
          <fieldset>
            <legend>{{ $tc("worklist.patient") }}*</legend>
            <div class="information-icon-wrapper">
              <div><information-outline-icon class="information-icon" /></div>
              <div>{{ $t("pleaseSelectPatient") }}</div>
            </div>
            <div class="form-group form-row">
              <div class="col-md-12">
                <div v-if="patient">
                  <input readonly class="form-control" disabled :value="patientName" />
                </div>
                <div v-else>
                  <resource-select
                    :fhirBaseUrl="fhirBaseUrl"
                    :resourceName="'Patient'"
                    :titleAttribute="patientSelector.titleAttribute"
                    :subtitleAttributes="patientSelector.subtitleAttributes"
                    :searchAttributes="patientSelector.searchAttributes"
                    :queryParams="patientSelector.queryParams"
                    :searchInputPlaceholder="$t('search')"
                    :token="token"
                    :required="true"
                    @error="handleError"
                    v-model="patient"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <hr />
          <fieldset class="inclusion-criteria">
            <legend>{{ $t("inclusionCriteria") }}*</legend>
            <div class="information-icon-wrapper">
              <div><information-outline-icon class="information-icon" /></div>
              <div>{{ $t("indicationInfo") }}</div>
            </div>
            <div class="indication-criteria">
              <!-- :questionnaireResponse="formData.inclusionCriteriaResponse" -->
              <questionnaire-renderer
                :questionnaire="questionnaire"
                :baseUrl="fhirBaseUrl"
                mode="FullQuestionnaire"
                :enableReturn="true"
                danger="red"
                :subject="patient"
                :locale="locale"
                @updated="onInclusionFactorsUpdated"
              ></questionnaire-renderer>
            </div>
          </fieldset>
          <hr />
          <fieldset>
            <legend>{{ $tc("worklist.diagnosis") }}</legend>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="icd10">{{ $t("worklist.diagnosis") }}*</label>
              <div class="col-md-9">
                <concept-select
                  :fhirBaseUrl="fhirBaseUrl"
                  resourceName="ValueSet"
                  url="http://molit.eu/fhir/ValueSet/icd-10-gm-c-II"
                  :showCode="true"
                  :showBorder="true"
                  :searchInputPlaceholder="$t('search')"
                  :token="token"
                  :required="true"
                  :expandValueSet="false"
                  @error="$emit('error')"
                  v-model="formData.icd10"
                />
              </div>
            </div>
            <div class="form-group form-row" v-if="icdo3list && icdo3list.length">
              <label class="col-md-3 col-form-label" for="subType">{{ $t("subtype") }}*</label>
              <div class="col-md-9">
                <v-select id="subType" required :options="icdo3list" v-model="formData.subType" :placeholder="icdo3list && icdo3list.length ? $t('pleaseSelect') : '-'" label="display">
                  <template #option="option">{{ option.display }}</template>
                  <template #no-options>{{ $t("noEntriesFound") }}</template>
                </v-select>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="date-of-diagnosis">{{ $t("dateOfDiagnosis") }}*</label>
              <div class="col-md-9">
                <div>
                  <date-input required v-model="formData.dateOfDiagnosis" :validation="false" :yearPlaceholder="$t('dateInput.year')" :monthPlaceholder="$t('dateInput.month')" :dayPlaceholder="$t('dateInput.day')" />
                  <small class="form-text text-muted">{{ $t("dateInfo") }}</small>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="diagnosis">{{ $t("uicc") }}*</label>
              <div class="col-md-9">
                <v-select :options="optionsUicc" v-model="formData.uicc" label="display" :reduce="option => option.code" :placeholder="$t('pleaseSelect')">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formData.uicc" v-bind="attributes" v-on="events" />
                  </template>
                </v-select>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="tnm">{{ $t("initialTnm") }}</label>
              <div class="col-md-9">
                <div class="form-row">
                  <div class="col">
                    <v-select :options="optionsT" v-model="formData.t" label="display" :reduce="option => option.code" :placeholder="`T - ${$t('pleaseSelect')}`"></v-select>
                  </div>
                  <div class="col">
                    <v-select :options="optionsN" v-model="formData.n" label="display" :reduce="option => option.code" :placeholder="`N - ${$t('pleaseSelect')}`"></v-select>
                  </div>
                  <div class="col">
                    <v-select :options="optionsM" v-model="formData.m" label="display" :reduce="option => option.code" :placeholder="`M - ${$t('pleaseSelect')}`"></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-9"><hr /></div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="current-status">{{ $t("currentStatus") }}*</label>
              <div class="col-md-9">
                <v-select id="current-status" required :options="optionsStatus" label="display" :placeholder="$t('pleaseSelect')" v-model="formData.currentStatus">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formData.currentStatus" v-bind="attributes" v-on="events" />
                  </template>
                </v-select>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="ecog">{{ $t("currentEcogScore") }}*</label>
              <div class="col-md-9">
                <v-select id="ecog" required :options="optionsEcog" label="display" :placeholder="$t('pleaseSelect')" v-model="formData.ecog">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!formData.ecog" v-bind="attributes" v-on="events" />
                  </template>
                </v-select>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="secondary-diagnosis">{{ $tc("relevantSecondaryDiagnoses") }}</label>
              <div class="col-md-9">
                <textarea id="secondary-diagnosis" class="form-control" rows="4" v-model="formData.additionalDiagnosis"></textarea>
              </div>
            </div>
          </fieldset>
          <hr />
          <fieldset class="performed-diagnostics">
            <legend>{{ $t("performedDiagnostics") }}</legend>
            <div class="form-group form-row" v-for="biomarker in formData.biomarkerList" :key="biomarker.code">
              <label class="col col-form-label" :for="biomarker.code">{{ biomarker.display }}</label>
              <div class="ml-auto"></div>
              <div v-if="biomarker.code === '81695-9'">
                <div class="form-check form-check-inline" v-for="outcome in optionsMSI" :key="outcome.code">
                  <input class="form-check-input" type="radio" :name="biomarker.code" :id="biomarker.code + '-' + outcome.code" :value="outcome" v-model="biomarker.result" />
                  <label class="form-check-label" :for="biomarker.code + '-' + outcome.code">{{ $t(outcome.display.toLocaleLowerCase()) }}</label>
                </div>
              </div>
              <div v-else>
                <div class="form-check form-check-inline" v-for="outcome in optionsOutcomes" :key="outcome.code">
                  <input class="form-check-input" type="radio" :name="biomarker.code" :id="biomarker.code + '-' + outcome.code" :value="outcome" v-model="biomarker.result" />
                  <label class="form-check-label" :for="biomarker.code + '-' + outcome.code">{{ getOutcomeDisplay(outcome) }}</label>
                </div>
              </div>
            </div>
          </fieldset>
          <hr />
          <fieldset>
            <legend>{{ $tc("courseOfDisease") }}</legend>
            <table class="table table-bordered" style="table-layout: fixed">
              <colgroup>
                <col style="width: 140px" />
                <col style="width: 140px" />
                <col style="width: 32%" />
                <col style="width: 68%" />
                <col style="width: 50px" />
              </colgroup>
              <thead>
                <tr class="row-header">
                  <th>{{ $t("therapyStartDate") }}*</th>
                  <th>{{ $t("therapyEndDate") }}</th>
                  <th>{{ $t("therapyType") }}/{{ $t("worklist.status") }}*</th>
                  <th>{{ $t("detailedInformation") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!formData.therapies || !formData.therapies.length">
                  <td colspan="5" class="text-center">{{ $t("noEntriesFound") }}</td>
                </tr>
                <tr v-for="(therapy, index) in formData.therapies" :key="index">
                  <td><input type="text" required class="form-control" placeholder="MM/JJJJ" pattern="^((0[1-9]|[12][0-9]|3[01])\/)?(1[0-2]|0[1-9])\/\d{4}$" v-model="therapy.startDate" /></td>
                  <!-- TODO fix pattern so Date can include dd or not -->
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="MM/JJJJ"
                      pattern="^((0[1-9]|[12][0-9]|3[01])\/)?(1[0-2]|0[1-9])\/\d{4}$"
                      v-model="therapy.endDate"
                      v-if="therapy.therapyOption === 'Systemische Therapie' || therapy.therapyOption === 'Bestrahlung'"
                    />
                  </td>
                  <td>
                    <v-select v-model="therapy.therapyOption" :options="optionsTherapy" :placeholder="$t('pleaseSelect')">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!therapy.therapyOption" v-bind="attributes" v-on="events" />
                      </template>
                    </v-select>
                  </td>
                  <td>
                    <concept-select
                      v-if="therapy.therapyOption === 'Systemische Therapie'"
                      :fhirBaseUrl="fhirBaseUrl"
                      resourceName="ValueSet"
                      url="http://molit.eu/fhir/ValueSet/substances-protocols"
                      sort
                      :searchInputPlaceholder="$t('search')"
                      :token="token"
                      @error="handleError"
                      v-model="therapy.medication"
                      multiple
                      required
                      mapToConceptMap
                      conceptMapUrl="http://molit.eu/fhir/vitu/ConceptMap/protocols-to-atc"
                      :sortFunction="medicationSortFunction"
                      class="multiple"
                    ></concept-select>
                    <v-select v-else-if="therapy.therapyOption === 'Status'" required :options="optionsStatus" label="display" :placeholder="$t('pleaseSelect')" v-model="therapy.status">
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!therapy.status" v-bind="attributes" v-on="events" />
                      </template>
                    </v-select>
                    <input v-else-if="therapy.therapyOption === 'Operation'" type="text" class="form-control" :placeholder="$tc('comment') + ' - R0/R1'" v-model="therapy.note" />
                    <input v-else-if="therapy.therapyOption === 'Bestrahlung'" type="text" class="form-control" :placeholder="$tc('comment')" v-model="therapy.note" />
                    <span v-else class="form-control no-border">&mdash;</span>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link delete-icon" @click="onRemoveTherapy(index)"><delete-icon /></button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-right">
              <button type="button" @click="onAddTherapy" class="btn btn-secondary">{{ $t("addEntry") }}</button>
            </div>
          </fieldset>
          <hr />
          <fieldset>
            <legend>{{ $tc("document", 2) }}</legend>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label">{{ $t("mostRecentDischargeSummary") }}</label>
              <div class="col">
                <b-form-file v-model="formData.dischargeSummary" :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label">{{ $tc("relevantPathologyReport") }}</label>
              <div class="col-md-9">
                <b-form-file v-model="formData.report" :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
            </div>
            <div class="form-group form-row" v-for="(document, index) in formData.additionalDocuments" :key="index">
              <label class="col-md-3 col-form-label">{{ $t("additionalDocument") }}</label>
              <div class="col">
                <b-form-file v-model="formData.additionalDocuments[index]" :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
              <div class="col-md-auto">
                <button type="button" class="btn btn-link delete-icon" @click="onRemoveAdditionalDocuments(index)"><delete-icon /></button>
              </div>
            </div>
            <div class="text-right">
              <button type="button" @click="onAddAdditionalDocuments" class="btn btn-secondary">{{ $t("addAdditionalDocument") }}</button>
            </div>
          </fieldset>
          <hr />
          <fieldset>
            <legend>{{ $tc("consent") }}</legend>
            <div class="form-group form-row">
              <label for="consentForDataDonation" class="col-md-3"></label>
              <div class="col-md-9">
                <div>
                  <strong>{{ $tc("tumorBoard") }}*</strong>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="consentForDataDonation" class="col-md-3"></label>
              <div class="col-md-9">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="consentForTumorboard" required v-model="formData.consentTumorboard" />
                  <label for="consentForTumorboard" class="form-check-label">{{ $t("discussionInterSite") }}</label>
                </div>
              </div>
            </div>
            <!-- <div class="form-group form-row">
              <label for="consentForDataDonation" class="col-md-3"></label>
              <div class="col-md-9">
                <div>
                  <strong>{{ $tc("dataDonation") }}</strong>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="consentForDataDonation" class="col-md-3"></label>
              <div class="col-md-9">
                <div class="form-check">
                  <input class="form-check-input" disabled type="checkbox" id="data-donation-all" @change="consentAllClicked" v-model="consentAll" />
                  <label for="data-donation-all" class="form-check-label">{{ $t("selectAll") }}</label>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label for="consentForDataDonation" class="col-md-3"></label>
              <div class="col-md-9">
                <div class="form-check">
                  <input class="form-check-input" disabled type="checkbox" id="consentScientificUsage" v-model="formData.consentScientificUsage" />
                  <label for="consentScientificUsage" class="form-check-label">Erhebung und wissenschaftliche Nutzung der Patientendaten</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" disabled type="checkbox" id="consentScientificQuestions" v-model="formData.consentScientificQuestions" />
                  <label for="consentScientificQuestions" class="form-check-label">Erneuter Kontakt für wissenschaftliche Rückfragen</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" disabled type="checkbox" id="consentAdditionalReports" v-model="formData.consentAdditionalReports" />
                  <label for="consentAdditionalReports" class="form-check-label">Erneuter Kontakt für medizinische Zusatzbefunde</label>
                </div>
              </div>
            </div> -->
          </fieldset>
          <hr />
          <fieldset>
            <legend>{{ $tc("furtherInformation") }}</legend>
            <div class="form-group form-row">
              <label class="col-md-3 col-form-label" for="comment">{{ $tc("comment") }}</label>
              <div class="col-md-9">
                <textarea id="comment" class="form-control" v-model="formData.comment" rows="4"></textarea>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="page-footer">
        <div class="spacer"></div>
        <button class="btn btn-secondary btn-cancel" @click="onCancel">{{ $t("cancel") }}</button>
        <!-- <button class="btn btn-primary" @click="onSubmit">{{ $t("admin.save") }}</button> -->
        <button class="btn btn-primary" @click="onFinalize">{{ $t("finalizeCaseRegistration") }}</button>
      </div>
      <!-- <div class="page-footer">
        <div class="spacer"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/ui/Spinner";
import Breadcrumps from "@/components/ui/Breadcrumps";
import DateInput from "@/components/ui/DateInput";
import NotificationPanels from "@/components/ui/NotificationPanels";
import DeleteIcon from "vue-material-design-icons/Delete";
import InformationOutlineIcon from "vue-material-design-icons/InformationOutline";
import ResourceSelect from "@/components/ui/ResourceSelect";
import ConceptSelect from "@/components/ui/ConceptSelect";
import config from "@/config/config";
import { mapState } from "vuex";
import { addResourceToTransaction, editClinicalCase, updateTask } from "@/api/worklist-api";
import { fetchPatient, fetchResource, submitResource, fetchResources, mapFhirResponse } from "@molit/fhir-api";
import { getStringFromHumanName } from "@molit/fhir-util";
import notifications from "@/mixins/notifications";
import { get } from "lodash";
import { QuestionnaireRenderer } from "@molit/fhir-components";
import { transactionTemplate } from "@/templates/fhir-templates";
import { cloneDeep } from "lodash";
import axios from "axios";
import fhirpath from "@/assets/js/fhirpath.min.js";
import moment from "moment";

const FHIRPATH_CONDITION = "Bundle.entry.resource.where(resourceType='Condition').where(clinicalStatus.coding.code='active').single()";
const FHIRPATH_DIAGNISIS_CODE = "Condition.code.coding.code";
const FHIRPATH_DIAGNISIS_DATE = "Condition.onsetDateTime";
const FHIRPATH_DIAGNISIS_DISPLAY = "Condition.code.coding.display";
const FHIRPATH_CONDITION_INACTIVE = "Bundle.entry.resource.where(resourceType='Condition').where(clinicalStatus.coding.code='inactive')";
const FHIRPATH_TNM = `Bundle.entry.resource.where(resourceType='Observation')
.where(code.coding.system='http://loinc.org' and code.coding.code='75620-5').single()`;
const FHIRPATH_TNM_T = `Observation.component
.where(valueCodeableConcept.coding.system='https://iqtig.org/fhir/CodeSystem/kkr/TNMT_CS/').valueCodeableConcept.coding.display`;
const FHIRPATH_TNM_N = `Observation.component
.where(valueCodeableConcept.coding.system='https://iqtig.org/fhir/CodeSystem/kkr/TNMN_CS/').valueCodeableConcept.coding.display`;
const FHIRPATH_TNM_M = `Observation.component
.where(valueCodeableConcept.coding.system='https://iqtig.org/fhir/CodeSystem/kkr/TNMM_CS/').valueCodeableConcept.coding.display`;
const FHIRPATH_ECOG = `Bundle.entry.resource.where(resourceType='Observation')
.where(code.coding.system='http://loinc.org' and code.coding.code='89247-1')`;
const FHIRPATH_ECOG_DISPLAY = `Observation.valueCodeableConcept.coding`;
const FHIRPATH_MEDICATIONS = `Bundle.entry.where(resource.resourceType='Medication')`;
const FHIRPATH_MEDICATION_LIST = `Medication.ingredient.itemCodeableConcept.coding.where(system='http://www.whocc.no/atc')`;
const FHIRPATH_MEDICATIONSTATEMENTS = `Bundle.entry.resource.where(resourceType='MedicationStatement')`;
const FHIRPATH_MEDICATIONSTATEMENT_MEDICATION_ID = `MedicationStatement.medicationReference.id`;
const FHIRPATH_MEDICATIONSTATEMENT_EFFECTIVEPERIOD = `MedicationStatement.effectivePeriod`;
const FHIRPATH_OPERATIONS = `Bundle.entry.resource.where(resourceType='Procedure').where(code.coding.system contains 'http://fhir.de/CodeSystem/bfarm/ops' and code.coding.code.all(matches('5-[a-z0-9]{1,3}.[a-z0-9]{1,3}')))`; //5-###.## matches('/5-[a-z0-9]{3}.[a-z0-9]{2}/')
const FHIRPATH_OPERATION_CODE = `Procedure.code.coding.code`;
const FHIRPATH_OPERATION_DATE = `Procedure.performedDateTime`;
const FHIRPATH_OPERATION_OUTCOME = `Procedure.outcome.coding.single()`;
const FHIRPATH_RADIATION_THERAPIES = `Bundle.entry.resource.where(resourceType='Procedure').where(category.coding.system contains 'http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl' and category.coding.code contains 'C15313')`;
const FHIRPATH_RADIATION_THERAPY_PERIOD = `Procedure.performedPeriod`;
const FHIRPATH_RADIATION_THERAPY_BODYSITE = `Procedure.bodySite.coding.display`;
const FHIRPATH_RADIATION_THERAPY_DOSIS = `Procedure.extension.where(url='https://purl.org/elab/fhir/network/StructureDefinition/1/Dosis').valueString`;
const FHIRPATH_STATUS = `Bundle.entry.resource.where(resourceType='Observation')
.where(code.coding.system='http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl' and code.coding.code='C96643')`;
const FHIRPATH_STATUS_FERNMETASTASEN = `Bundle.entry.resource.where(resourceType='Observation')
.where(code.coding.system='http://loinc.org' and code.coding.code='21907-1')`;
const FHIRPATH_STATUS_CODE = `Observation.valueCodeableConcept.coding.code`;
const FHIRPATH_STATUS_DATE = `Observation.effectiveDateTime`;
export default {
  mixins: [notifications],

  data() {
    return {
      loading: true,
      prefetchUrls: [
        "ValueSet/$expand?url=http://molit.eu/fhir/vitu/ValueSet/working-list-status",
        "ValueSet/$expand?url=http://molit.eu/fhir/vitu/ValueSet/uicc-stage",
        "ValueSet/$expand?url=http://molit.eu/fhir/vitu/ValueSet/tnm-t",
        "ValueSet/$expand?url=http://molit.eu/fhir/vitu/ValueSet/tnm-n",
        "ValueSet/$expand?url=http://molit.eu/fhir/vitu/ValueSet/tnm-m",
        "ValueSet?url=http://molit.eu/fhir/vitu/ValueSet/treatment-context",
        "ConceptMap?url=http://molit.eu/fhir/vitu/ConceptMap/protocols-to-atc",
        "ConceptMap?url=http://molit.eu/fhir/vitu/ConceptMap/icd10-to-subtype",
        "ValueSet?url=http://molit.eu/fhir/vitu/ValueSet/biomarker",
        "ValueSet?url=http://molit.eu/fhir/vitu/ValueSet/MSIVITUAnswerCodes",
        "ValueSet?url=http://loinc.org/vs/LL3044-6",
        "ValueSet?url=http://loinc.org/vs/LL529-9"
      ],
      prefetchData: [],
      optionsTherapy: ["Systemische Therapie", "Bestrahlung", "Operation", "Status"],
      formData: {
        icd10: null, // condition
        subType: null, //
        dateOfDiagnosis: null,
        uicc: null,
        t: null,
        n: null,
        m: null,
        currentStatus: null,
        ecog: null,
        additionalDiagnosis: null,
        therapies: [],
        consentForTumorboard: null,
        inclusionCriteriaResponse: null,
        comment: null,
        biomarkerList: null,
        consentTumorboard: null,
        consentScientificUsage: null,
        consentScientificQuestions: null,
        consentAdditionalReports: null,
        dischargeSummary: null,
        report: null,
        additionalDocuments: []
      },
      patientSelector: {
        searchAttributes: [
          {
            name: "Name",
            value: "name:contains"
          }
        ],
        queryParams: {
          _sort: "name",
          active: true
        },
        titleAttribute: {
          value: "name",
          type: "HumanName"
        },
        subtitleAttributes: [
          {
            name: this.$t("worklist.birthDate"),
            value: "birthDate",
            type: "Date"
          }
        ]
      },
      questionnaire: {
        resourceType: "Questionnaire",
        title: "Inklusionskriterien",
        url: "http://molit.eu/fhir/Questionnaire/inclusion-criteria",
        description: "Fragebogen Inklusionskriterien für eine molekulare Diagnostik",
        status: "active",
        publisher: "MOLIT Institut gGmbH",
        date: "2020-02-07T15:11:00+01:00",
        item: [
          {
            linkId: "1",
            prefix: "1.",
            text: "Patient ohne weitere leitliniengerechte Therapieoptionen",
            type: "boolean",
            required: true
          },
          {
            linkId: "2",
            prefix: "2.",
            text: "Ungewöhnlicher Erkrankungsverlauf",
            type: "boolean",
            required: true
          },
          {
            linkId: "3",
            prefix: "3.",
            text: "Patienten mit seltenen Tumorerkrankungen",
            type: "boolean",
            required: true
          },
          {
            linkId: "4",
            prefix: "4.",
            text: "Seltene Histologie",
            type: "boolean",
            required: true
          },
          {
            linkId: "5",
            prefix: "5.",
            text: "Junges Erkrankungsalter bezogen auf Tumorentität",
            type: "boolean",
            required: true
          },
          {
            linkId: "6",
            prefix: "6.",
            text: "Verdacht auf Hereditäre Tumorerkrankung",
            type: "boolean",
            required: true
          },
          {
            linkId: "7",
            prefix: "7.",
            text: "Up-Front (z.B. CUP = C80, Pankreas = C25)",
            type: "boolean",
            required: true
          }
        ]
      },
      medicationSortFunction: (c1, c2) => {
        if (!c1.selectTitle || !c2.selectTitle) {
          return 0;
        }
        if (c1.selectTitle.includes("(") && !c2.selectTitle.includes("(")) {
          return 1;
        }
        if (!c1.selectTitle.includes("(") && c2.selectTitle.includes("(")) {
          return -1;
        }
        return c1.selectTitle.localeCompare(c2.selectTitle);
      },
      patient: null,
      task: null,
      consentAll: null,
      responseInitialized: false
    };
  },

  computed: {
    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    adtImportEnabled() {
      return config.ADT_IMPORT_ENABLED;
    },

    ...mapState({
      token: state => state.authentication.keycloak.token,
      roles: state => state.authentication.keycloak.realmAccess.roles
    }),

    locale() {
      return this.$i18n.locale;
    },

    statuses() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/working-list-status");
      return get(valueSet, "expansion.contains", []).sort((e1, e2) => {
        return e1.code.localeCompare(e2.code);
      });
    },

    optionsUicc() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/uicc-stage");
      return get(valueSet, "expansion.contains", []);
    },

    optionsT() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/tnm-t");
      return get(valueSet, "expansion.contains", []);
    },

    optionsN() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/tnm-n");
      return get(valueSet, "expansion.contains", []);
    },

    optionsM() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/tnm-m");
      return get(valueSet, "expansion.contains", []);
    },

    conceptMapIcd10toIcdo3() {
      return this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ConceptMap/icd10-to-subtype");
    },

    conceptMapProtocols() {
      return this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ConceptMap/protocols-to-atc");
    },

    optionsStatus() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/treatment-context");
      return get(valueSet, "compose.include[0].concept", []);
    },

    optionsEcog() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://loinc.org/vs/LL529-9");
      return get(valueSet, "compose.include[0].concept", []);
    },

    optionsOutcomes() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://loinc.org/vs/LL3044-6");
      return get(valueSet, "compose.include[0].concept", []);
    },

    optionsMSI() {
      const valueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/MSIVITUAnswerCodes");
      return get(valueSet, "compose.include[0].concept", []).filter(o => o.code !== "LA11884-6");
    },

    icdo3list() {
      if (this.formData && this.formData.icd10 && this.formData.icd10.code) {
        let concept = this.conceptMapIcd10toIcdo3.group[0].element.find(concept => this.formData.icd10.code.includes(concept.code));
        if (concept) {
          return concept.target;
        }
      }

      return [];
    },

    patientName() {
      if (this.patient) {
        return getStringFromHumanName(this.patient.name);
      } else {
        return "";
      }
    },

    isIndicationCriteriaSelected() {
      if (!this.formData || !this.formData.inclusionCriteriaResponse || !this.formData.inclusionCriteriaResponse.item) {
        return false;
      }

      const trueAnswer = this.formData.inclusionCriteriaResponse.item.find(item => get(item, "answer[0].valueBoolean"));
      return trueAnswer ? true : false;
    }
  },

  methods: {
    async uploadCaseInformation() {
      try {
        const file = document.getElementById("uploadCaseInfo").files[0];
        const fd = new FormData();
        fd.append("file", file, file.name);
        const res = await axios.post(config.ADT_BACKEND_URL + "/uploadFile", fd, {
          headers: {
            Authorization: "Bearer " + this.token
          }
        });

        console.log(res.data);
        // console.log(JSON.stringify(res.data));

        const condition = fhirpath.evaluate(res.data, FHIRPATH_CONDITION);
        if (condition.length) {
          const diagnosisCode = fhirpath.evaluate(condition, FHIRPATH_DIAGNISIS_CODE);
          const diagnosisDate = fhirpath.evaluate(condition, FHIRPATH_DIAGNISIS_DATE);

          this.formData.icd10 = { code: diagnosisCode[0] };
          this.formData.dateOfDiagnosis = diagnosisDate[0].slice(0, 10);
        }
        //TODO Dispplay
        //TODO Subtyp

        const oldConditions = fhirpath.evaluate(res.data, FHIRPATH_CONDITION_INACTIVE);
        if (oldConditions.length) {
          let oldConditionsText;
          oldConditions.forEach(oldCondition => {
            const diagnosisCode = fhirpath.evaluate(oldCondition, FHIRPATH_DIAGNISIS_CODE);
            const diagnosisDate = fhirpath.evaluate(oldCondition, FHIRPATH_DIAGNISIS_DATE);
            const diagnosisDisplay = fhirpath.evaluate(oldCondition, FHIRPATH_DIAGNISIS_DISPLAY);
            if (diagnosisCode.length && diagnosisDate.length && diagnosisDisplay.length) {
              oldConditionsText = oldConditionsText + diagnosisDate[0].slice(0, 10);
              +": " + diagnosisCode[0] + " - " + diagnosisDisplay[0] + "\n";
            }
          });
          this.formData.additionalDiagnosis = oldConditionsText;
          //TODO Test if this works
        }

        const tnm = fhirpath.evaluate(res.data, FHIRPATH_TNM);
        if (tnm.length) {
          const t = fhirpath.evaluate(tnm, FHIRPATH_TNM_T);
          if (t.length) {
            this.formData.t = t[0];
          }
          const n = fhirpath.evaluate(tnm, FHIRPATH_TNM_N);
          if (n.length) {
            this.formData.n = n[0];
          }
          const m = fhirpath.evaluate(tnm, FHIRPATH_TNM_M);
          if (m.length) {
            this.formData.m = m[0];
          }
        }

        const ecog = fhirpath.evaluate(res.data, FHIRPATH_ECOG);
        if (ecog.length) {
          const ecogDisplay = fhirpath.evaluate(ecog, FHIRPATH_ECOG_DISPLAY);
          if (ecogDisplay[0].display != "Unbekannt") {
            this.formData.ecog = {
              code: ecogDisplay[0].code,
              display: ecogDisplay[0].display
            };
            //TODO Test if this works
          }
        }

        const medicationStatements = fhirpath.evaluate(res.data, FHIRPATH_MEDICATIONSTATEMENTS);
        const medications = fhirpath.evaluate(res.data, FHIRPATH_MEDICATIONS);
        if (medicationStatements.length && medications.length) {
          medicationStatements.forEach(medicationStatement => {
            let newTherapy = { therapyOption: "Systemische Therapie" };
            const medicationReference = fhirpath.evaluate(medicationStatement, FHIRPATH_MEDICATIONSTATEMENT_MEDICATION_ID);
            if (medicationReference.length) {
              const fhirpathMedication = "where(fullUrl='" + medicationReference[0] + "').resource";
              const medication = fhirpath.evaluate(medications, fhirpathMedication);
              const medicationList = fhirpath.evaluate(medication, FHIRPATH_MEDICATION_LIST);
              newTherapy = { ...newTherapy, medication: medicationList };
            }
            const effectivePeriod = fhirpath.evaluate(medicationStatement, FHIRPATH_MEDICATIONSTATEMENT_EFFECTIVEPERIOD);
            if (effectivePeriod[0].hasOwnProperty("start")) {
              const start = moment(effectivePeriod[0].start).format("DD/MM/YYYY");
              newTherapy = { ...newTherapy, startDate: start };
            }
            if (effectivePeriod[0].hasOwnProperty("end")) {
              const end = moment(effectivePeriod[0].end).format("DD/MM/YYYY");
              newTherapy = { ...newTherapy, endDate: end };
            }
            this.formData.therapies.push(newTherapy);
          });
        }

        const operations = fhirpath.evaluate(res.data, FHIRPATH_OPERATIONS);
        if (operations.length) {
          operations.forEach(operation => {
            let newTherapy = { therapyOption: "Operation" };
            const outcome = fhirpath.evaluate(operation, FHIRPATH_OPERATION_OUTCOME);
            const code = fhirpath.evaluate(operation, FHIRPATH_OPERATION_CODE);
            if (outcome.length && code.length) {
              const text = code.join(", ") + "; " + outcome[0].code + " - " + outcome[0].display;
              newTherapy = { ...newTherapy, note: text };
            }
            const date = fhirpath.evaluate(operation, FHIRPATH_OPERATION_DATE);
            if (date.length) {
              const start = moment(date[0]).format("DD/MM/YYYY");
              newTherapy = { ...newTherapy, startDate: start };
            }
            this.formData.therapies.push(newTherapy);
          });
        }

        const radiationTherapies = fhirpath.evaluate(res.data, FHIRPATH_RADIATION_THERAPIES);
        if (radiationTherapies.length) {
          radiationTherapies.forEach(radiationTherapy => {
            let newTherapy = { therapyOption: "Bestrahlung" };
            const bodySite = fhirpath.evaluate(radiationTherapy, FHIRPATH_RADIATION_THERAPY_BODYSITE);
            const dosis = fhirpath.evaluate(radiationTherapy, FHIRPATH_RADIATION_THERAPY_DOSIS);
            let text = "";
            if (bodySite.length && dosis.length) {
              let i;
              for (i = 0; i < dosis.length; i++) {
                text = text + bodySite.join(", ") + " - " + dosis[i];
                if (!(dosis.length - i === 1)) {
                  text = text + ", ";
                }
              }
              newTherapy = { ...newTherapy, note: text };
            }
            const performedPeriod = fhirpath.evaluate(radiationTherapy, FHIRPATH_RADIATION_THERAPY_PERIOD);
            if (performedPeriod[0].hasOwnProperty("start")) {
              const start = moment(performedPeriod[0].start).format("DD/MM/YYYY");
              newTherapy = { ...newTherapy, startDate: start };
            }
            if (performedPeriod[0].hasOwnProperty("end")) {
              const end = moment(performedPeriod[0].end).format("DD/MM/YYYY");
              newTherapy = { ...newTherapy, endDate: end };
            }
            this.formData.therapies.push(newTherapy);
          });
        }

        const statusList = fhirpath.evaluate(res.data, FHIRPATH_STATUS);
        if (statusList.length) {
          statusList.forEach(status => {
            let newTherapy = { therapyOption: "Status" };
            const statusCode = fhirpath.evaluate(status, FHIRPATH_STATUS_CODE);
            if (statusCode.length) {
              switch (statusCode[0]) {
                case "P":
                  newTherapy = { ...newTherapy, status: { code: "C35571", display: "Progrediente Erkrankung" } };
                  break;
                case "K":
                  newTherapy = { ...newTherapy, status: { code: "C18213", display: "Stable Disease" } };
                  break;
                case "T":
                  newTherapy = { ...newTherapy, status: { code: "C18058", display: "Teilremission" } };
                  break;
                case "V":
                  newTherapy = { ...newTherapy, status: { code: "C4870", display: "Vollremission" } };
                  break;
                case "R":
                  newTherapy = { ...newTherapy, status: { code: "C4870", display: "Vollremission" } };
                  break;
                case "D":
                  newTherapy = { ...newTherapy, status: { code: "C1111", display: "Divergentes Geschehen" } };
                  break;
                default:
                  break;
              }
            }
            if (newTherapy.hasOwnProperty("status")) {
              const statusDate = fhirpath.evaluate(status, FHIRPATH_STATUS_DATE);
              if (statusDate.length) {
                const start = moment(statusDate[0]).format("DD/MM/YYYY");
                newTherapy = { ...newTherapy, startDate: start };
              }
              this.formData.therapies.push(newTherapy);
            }
          });
        }

        const statusListFernmetastasen = fhirpath.evaluate(res.data, FHIRPATH_STATUS_FERNMETASTASEN);
        if (statusListFernmetastasen.length) {
          statusListFernmetastasen.forEach(status => {
            let newTherapy = { therapyOption: "Status" };
            const statusCode = fhirpath.evaluate(status, FHIRPATH_STATUS_CODE);
            if (statusCode.length) {
              switch (statusCode[0]) {
                case "R":
                  newTherapy = { ...newTherapy, status: { code: "C158837", display: "Neue Fernmetastasierung" } };
                  break;
                default:
                  break;
              }
            }
            if (newTherapy.hasOwnProperty("status")) {
              const statusDate = fhirpath.evaluate(status, FHIRPATH_STATUS_DATE);
              if (statusDate.length) {
                const start = moment(statusDate[0]).format("DD/MM/YYYY");
                newTherapy = { ...newTherapy, startDate: start };
              }
              this.formData.therapies.push(newTherapy);
            }
          });
        }
      } catch (e) {
        this.handleError(e);
      }
    },

    async onSubmit() {
      try {
        this.loading = true;
        await editClinicalCase(this.fhirBaseUrl, this.token, this.patient, null, this.formData);
        const status = this.statuses.find(status => status.code != null && status.code.includes("finished-case-submission"));
        if (status && this.task && this.task.businessStatus && this.task.businessStatus.text === "Neu") {
          await updateTask(this.fhirBaseUrl, this.token, this.task, status, null, null);
        }

        this.$router.push({ name: "clinical-case", params: { id: this.task.id }, query: { success: "worklist.successfullyEditedCase" } });
      } catch (e) {
        this.handleError(e);
        this.loading = false;
      }
    },

    onFinalize() {
      if (!this.isIndicationCriteriaSelected) {
        this.warning = this.$t("indicationWarning");
        return;
      }
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }
      this.onSubmit();
    },

    onError(e) {
      this.handleError(e);
    },

    onInclusionFactorsUpdated(response) {
      if (response && !this.responseInitialized) {
        for (let i = 0; i < response.item.length; i++) {
          const item = response.item[i];
          item.answer.push({
            valueBoolean: false
          });
        }
        this.responseInitialized = true;
      }
      this.formData.inclusionCriteriaResponse = response;
    },

    onAddTherapy() {
      this.formData.therapies.push({});
    },

    onRemoveTherapy(index) {
      this.formData.therapies.splice(index, 1);
    },

    onAddAdditionalDocuments() {
      this.formData.additionalDocuments.push(null);
    },

    onRemoveAdditionalDocuments(index) {
      this.formData.additionalDocuments.splice(index, 1);
    },

    onCancel() {
      this.$router.push({ name: "clinical-cases", params: { id: this.task.id } });
    },

    consentAllClicked() {
      if (this.consentAll) {
        this.formData.consentAdditionalReports = true;
        this.formData.consentScientificQuestions = true;
        this.formData.consentScientificUsage = true;
      } else {
        this.formData.consentAdditionalReports = false;
        this.formData.consentScientificQuestions = false;
        this.formData.consentScientificUsage = false;
      }
    },

    async fetchClinicalCase(id) {
      this.task = (await fetchResource(this.fhirBaseUrl, "Task", id, {}, this.token)).data;
      if (this.task.for && this.task.for.reference) {
        this.patient = (await fetchPatient(this.fhirBaseUrl, this.task.for.reference.split("/")[1], {}, this.token)).data;
      }
    },

    async fetchClinicalCaseByPatientId(id) {
      const response = await fetchResources(this.fhirBaseUrl, "Task", { patient: id }, this.token);
      const task = mapFhirResponse(response)[0];
      if (!task) {
        throw Error("Task not found, please select another Patient.");
      }
      this.task = task;
      if (this.task.for && this.task.for.reference) {
        this.patient = (await fetchPatient(this.fhirBaseUrl, this.task.for.reference.split("/")[1], {}, this.token)).data;
      }
    },

    async initialize() {
      try {
        if (this.$route.params.id) {
          this.fetchClinicalCase(this.$route.params.id);
        } else if (this.$route.query.patientId) {
          this.fetchClinicalCaseByPatientId(this.$route.query.patientId);
        }

        const transaction = cloneDeep(transactionTemplate);
        this.prefetchUrls.forEach(url => {
          addResourceToTransaction(transaction, undefined, undefined, "GET", url);
        });

        const response = await submitResource(this.fhirBaseUrl, transaction, this.token);
        this.prefetchData = response.data.entry.map(entry => {
          if (entry.resource && entry.resource.resourceType === "Bundle") {
            entry.resource = get(entry, "resource.entry[0].resource");
          }
          return entry.resource;
        });
        let biomarkerValueSet = this.prefetchData.find(r => r && r.url === "http://molit.eu/fhir/vitu/ValueSet/biomarker");
        this.formData.biomarkerList = get(biomarkerValueSet, "compose.include[0].concept", []);
        this.formData.biomarkerList.push(get(biomarkerValueSet, "compose.include[1].concept[0]", []));
        this.formData.biomarkerList.forEach(biomarker => {
          this.$set(biomarker, "result", { code: "LA4489-6", display: "Unknown" });
        });

        this.loading = false;
      } catch (e) {
        this.handleError(e);
        this.loading = false;
      }
    },

    getOutcomeDisplay(outcome) {
      if (outcome.display === "Yes") {
        return this.$t("positive");
      } else if (outcome.display === "No") {
        return this.$t("negative");
      } else {
        return this.$t(outcome.display.toLocaleLowerCase());
      }
    }
  },

  mounted() {
    this.initialize();
  },

  components: {
    Breadcrumps,
    ConceptSelect,
    DateInput,
    ResourceSelect,
    DeleteIcon,
    InformationOutlineIcon,
    NotificationPanels,
    QuestionnaireRenderer,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.btn-cancel,
.btn-delete {
  margin-right: 0.5rem;
}

.delete-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
}

.btn-link {
  padding: 0;
}

.spinner {
  margin-top: 1rem;
}

.emphasized-option {
  font-weight: bold;
  white-space: pre-wrap;
}

.performed-diagnostics {
  label {
    text-align: left;
    padding: 0.2rem;
  }

  .form-row:hover {
    background: lighten($vitu-background, 3.5%);
  }

  .form-group {
    margin: 0;
  }
}
</style>

<style lang="scss">
.inclusion-criteria {
  p {
    margin: 0 !important;
  }

  .card-margin-bottom {
    display: none;
  }

  .list-complete-item {
    .card {
      border: 0 !important;
      margin: 0 !important;
      background: initial;
      cursor: initial;

      > div:nth-child(2) {
        display: none;
      }
    }

    .card-body {
      padding: 0;
      > div:first-child {
        display: none;
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        padding: 0.2rem;

        &:hover {
          background: lighten($vitu-background, 3.5%);
        }
      }
      > div > div:not(.form-group) > div:nth-child(2) {
        display: none;
      }
    }

    hr,
    br {
      display: none;
    }

    h2 {
      font-size: 1rem;
      font-weight: initial;
      cursor: initial;
      margin: 0;
    }

    .radio-button-card {
      min-height: 0 !important;
      cursor: initial !important;
      background: initial !important;
      flex: initial !important;
    }

    .form-group {
      display: flex;
      margin: 0;
    }

    .form-check {
      padding-left: 1rem;
      display: flex;
    }

    .form-check-label.title {
      font-size: initial;
      font-weight: initial;
      display: initial;
      padding: 0;
    }

    .form-check-input.radio-button {
      width: initial;
      height: initial;
      margin-left: initial;
      position: initial;
    }
  }
}
</style>
