<template>
  <div class="main">
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
      :fluid="true"
    />
    <div class="container">
      <breadcrumps
        :breadcrumps="[
          { name: $t('planner.conferencePlanner'), route: { name: 'planner' } },
          { name: $t('conference.conference'), route: { name: 'conference-edit' } }
        ]"
      />

      <div class="page-header">
        <h5 class="headline">{{ $tc("conference.conference", 1) }}</h5>
        <div class="spacer"></div>
      </div>

      <div class="page-body">
        <div v-if="!loading">
          <div>
            <room-details-edit v-if="mode === 'edit' || mode === 'new'" @close="onCloseEdit" @error="handleError"></room-details-edit>
            <div v-else>
              <div style="display: flex; justify-content: space-between">
                <h5 v-if="conference && conference.tumorConference">
                  {{ conference.tumorConference.description }}
                </h5>
                <div v-if="mode === 'details'">
                  <span class="btn" @click="mode = 'edit'"><edit-icon class="icon"></edit-icon></span>
                  <span v-if="mode === 'details'" class="btn" @click="deleteRoom(conference.janusId)"><delete-icon class="icon"></delete-icon></span>
                </div>
              </div>
              <div class="form-group row" v-if="!unlimited && conference && conference.tumorConference && conference.tumorConference.date">
                <label for="date" class="col-4 col-md-3 col-form-label">{{ $t("dateAndTime") }}</label>
                <div class="col-8 col-md-9 info-text">
                  <h6>{{ $d(new Date(conference.tumorConference.date), "long") }}</h6>
                </div>
              </div>
              <div class="form-group row" v-if="unlimited">
                <label for="date" class="col-4 col-md-3 col-form-label">{{ $t("permanent") }}</label>
                <div class="col-8 col-md-9 info-text">
                  <h6>{{ $t("yes") }}</h6>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-md-3 col-form-label" for="color">{{ $t("color") }}</label>
                <div class="col-8 col-md-9">
                  <label class="color-btn" :style="{ background: color }">
                    <input type="radio" name="colorButton" id="currentColor" style="opacity: 0" v-model="color" disabled />
                  </label>
                </div>
              </div>
              <!-- <div class="form-group row">
                <label class="col-4 col-md-3 col-form-label" for="showVideosInitially">{{ $t("showVideosInitially") }}</label>
                <div class="col-8 col-md-9 info-text">
                  <h6 v-if="showVideosInitially">{{ $t("yes") }}</h6>
                  <h6 v-if="!showVideosInitially">{{ $t("no") }}</h6>
                </div>
              </div> -->
            </div>
          </div>

          <hr />

          <b-tabs class="tabs" v-if="mode === 'details'">
            <b-tab active class="tab-pane pt-3" :title="$t('conference.participants')">
              <room-participants v-if="conference" :roomId="conference.janusId" />
            </b-tab>
            <b-tab class="tab-pane pt-3" :title="$t('worklist.cases')">
              <room-cases v-if="conference" :roomId="conference.id" :roomDate="dateTime" :roomText="conference.tumorConference.description" :janusId="conference.janusId" />
            </b-tab>
          </b-tabs>
        </div>
        <spinner v-if="loading && !error" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanels from "@/components/ui/NotificationPanels";
import notifications from "@/mixins/notifications";
import Breadcrumps from "@/components/ui/Breadcrumps";
import { mapState } from "vuex";
import RoomParticipants from "@/components/RoomParticipants";
import RoomCases from "@/components/RoomCases";
import RoomDetailsEdit from "@/components/RoomDetailsEdit";
import DeleteIcon from "vue-material-design-icons/Delete";
import editIcon from "vue-material-design-icons/Pencil";
import Spinner from "vue-simple-spinner";
import { flatui1 } from "@/model/color-palettes";
import moment from "moment";

import { getRoom, deleteRoom } from "@/api/video-api";
export default {
  mixins: [notifications],

  data() {
    return {
      conference: null,
      loading: false,

      title: null,
      unlimited: false,
      date: null,
      time: "12:00",
      color: "#bdc3c7",
      showVideosInitially: false,
      roomId: null,
      tumorConferenceId: null,

      mode: "details",

      colorButtons: flatui1
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token
    }),

    isExisting() {
      return this.$route.params.id && this.$route.params.id !== "new";
    },

    dateTime() {
      if (this.conference != null && this.conference.tumorConference != null && this.conference.tumorConference.date != null) {
        return moment(this.conference.tumorConference.date).format();
      }
      return "";
    }
  },

  methods: {
    async onCloseEdit(id) {
      if (this.mode === "new" && id == null) {
        this.$router.push({ name: "planner" });
      } else if (this.mode === "new" && id != null) {
        await this.getRoom(id);
        this.mode = "details";
      } else {
        await this.getRoom(this.$route.params.id);
        this.mode = "details";
      }
    },

    async deleteRoom(roomId) {
      const answer = await this.$bvModal.msgBoxConfirm(this.$t("reallyDeleteConference"), {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("deleteConference"),
        okVariant: "danger",
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        this.deleteInProgress = true;
        try {
          await deleteRoom(roomId, this.token);
          this.deleteInProgress = false;
          this.$router.push({ name: "planner", query: { success: "conferenceDeleteSuccessful" } });
        } catch (e) {
          this.handleError(e);
          this.deleteInProgress = false;
        }
      }
    },

    async getRoom(id) {
      try {
        let response = await getRoom(this.token, id);
        if (response.status === 200 && response.data) {
          const room = response.data;
          this.conference = room; //TODO Corret?
          this.roomId = room.id;
          this.tumorConferenceId = room.tumorConference.id;
          this.title = room.tumorConference.description;
          this.color = room.videoRoomSettings.color;
          this.unlimited = room.videoRoomSettings.unlimited;
          this.showVideosInitially = room.videoRoomSettings.show_videos_initially;
          this.date = moment(room.tumorConference.date).format("yyyy-MM-DD");
          this.time = moment(room.tumorConference.date).format("HH:mm");
        }
      } catch (e) {
        this.handleError(e);
      }
    }
  },

  async created() {
    if (this.isExisting) {
      this.loading = true;
      await this.getRoom(this.$route.params.id);
      this.loading = false;
    } else {
      this.mode = "new";
    }
  },

  components: {
    Breadcrumps,
    DeleteIcon,
    editIcon,
    NotificationPanels,
    RoomParticipants,
    RoomCases,
    Spinner,
    RoomDetailsEdit
  }
};
</script>

<style lang="scss" scoped>
.main {
  background: white;
  min-height: calc(100vh - 66px);
}

.icon {
  color: $vitu-green;
  font-size: 20px;
  cursor: pointer;
  & :hover {
    color: darken($color: $vitu-green, $amount: 10);
  }
}

.color-btn {
  width: 24px;
  height: 24px;
  margin-top: 0.375rem;
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.info-text {
  padding-top: calc(0.375rem + 3px);
}
</style>
