<template>
  <div>
    <spinner class="spinner mt-2" line-fg-color="#148898" line-bg-color="#99bfbf" size="large" :speed="1.5" v-if="loading" />
    <div v-else>
      <div class="genetic-report-information pt-4">
        <div>
          <h4>{{ $t("serviceRequest") }}</h4>
          {{ $t("externalCaseId") }}: {{ generatedCaseId }}
        </div>
        <hr />

        <div class="row g-3" style="font-weight: bold">
          <div class="col-3">{{ $t("type") }}*</div>
          <div class="col-3">{{ $t("value") }}*</div>
          <div class="col-3">
            {{ $t("worklist.date") }}
          </div>
          <div class="col-3"></div>
        </div>

        <div class="row g-3 mt-1" v-for="observation in biomarkers" :key="observation.id">
          <div class="col-3">{{ getTypeForBiomarker(observation) }}</div>
          <div class="col-3">{{ getValueForBiomarker(observation) }}</div>
          <div class="col-3">
            {{ observation.effectiveDateTime }}
          </div>
          <div class="col-3">
            <a style="cursor: pointer" @click="deleteBiomarker(observation)"><delete-icon /></a>
          </div>
        </div>

        <form @submit.prevent="addBiomarker" ref="formbiomarker" autocomplete="off" class="mt-3">
          <div class="row g-3 mt-2">
            <div class="col-3">
              <select class="form-control" required v-model="newType">
                <option selected disabled :value="null">{{ $t("pleaseSelect") }}</option>
                <option value="HRD">HRD</option>
                <option value="TMB">TMB</option>
                <option value="MSI" disabled>MSI</option>
              </select>
            </div>
            <div class="col-3">
              <input type="text" required class="form-control" :placeholder="$t('value')" v-model="newValue" />
            </div>
            <div class="col-3">
              <input type="date" class="form-control" :placeholder="$t('worklist.date')" v-model="newDate" />
            </div>
            <div class="col-3">
              <button class="btn btn-secondary mb-3">{{ $t("add") }}</button>
            </div>
          </div>
        </form>

        <hr />
        <!-- 
        <div v-if="!loading">
          <div v-if="!loadingImport">
            <h4>{{ $t("importMolecularReport") }}</h4>
            <div class="container">
              <form autocomplete="off" ref="form" v-on:submit.prevent="onSubmit" class="row">
                <b-form-file v-model="importFiles" multiple required class="col mr-2 mb-2" :placeholder="$t('chooseFile')" accept="application/zip, .csv, .tsv" no-drop :browse-text="$t('browse')"></b-form-file>
                <button class="btn btn-primary col-md-auto pl-4 pr-4 mb-2">{{ $t("import") }}</button>
              </form>
            </div>
          </div>
          <spinner class="spinner mt-2" line-fg-color="#148898" line-bg-color="#99bfbf" size="large" :speed="1.5" v-if="loadingImport" />
          <hr />
        </div> -->

        <h4>{{ $t("worklist.reports") }}</h4>

        <b-tabs class="mt-3">
          <b-tab v-for="reportId in reportIds" :key="reportId" class="pt-3">
            <template slot="title">
              {{ reportId }}
            </template>
            <genomics-report
              v-if="reportId"
              :fhir-base-url="fhirBaseUrl"
              :id-genomics-report="reportId"
              :id-important-variants-list="idImportantVariantsList"
              :token="token"
              :enable-relevant-variants="true"
              @errorOccurred="onError"
              :locale="locale"
            />

            <div v-if="reportId" class="text-right">
              <hr />
              <button type="button" class="btn btn-secondary" @click="onDelete(reportId)">{{ $t("deleteReport") }}</button>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";
import { importantVariantsTemplate } from "@/templates/fhir-templates";
import { cloneDeep } from "lodash";
import { submitResource } from "@molit/fhir-api";
import { importMolecularReport, deleteMolecularReport } from "@/api/worklist-api";
import JSZip from "jszip";
import { mapState } from "vuex";
import Spinner from "vue-simple-spinner";
import DeleteIcon from "vue-material-design-icons/Delete";
import { get } from "lodash";

export default {
  props: {
    reportIds: {
      type: Array,
      default() {
        return [];
      }
    },

    patientId: {
      type: String
    },

    importantVariants: {
      type: Object
    },

    episodeOfCareId: {
      type: String,
      required: true
    },

    generatedCaseId: {
      type: String
    },

    biomarkers: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      localImportantVariants: this.importantVariants,
      loading: true,
      loadingImport: false,
      importFiles: [],

      newType: null,
      newValue: null,
      newDate: null,
      values: []
    };
  },

  computed: {
    ...mapState({
      keycloak: state => state.authentication.keycloak,
      token: state => state.authentication.keycloak.token
    }),

    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    dataImporterUrl() {
      return config.DATA_IMPORTER_URL;
    },

    locale() {
      return this.$i18n.locale;
    },

    idImportantVariantsList() {
      if (this.localImportantVariants != null) {
        return this.localImportantVariants.id;
      }
      return null;
    }
  },

  methods: {
    onError(error) {
      this.$emit("error", error);
    },

    onSubmit() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }
      this.uploadImport();
    },

    async onDelete(reportId) {
      const answer = await this.$bvModal.msgBoxConfirm(this.$t("reallyDeleteReport"), {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("deleteReport"),
        okVariant: "danger",
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        try {
          this.loading = true;
          await deleteMolecularReport(this.fhirBaseUrl, reportId, this.patientId, this.token);
          this.$emit("reload");
        } catch (e) {
          this.$emit("error", e);
          this.loading = false;
        }
      }
    },

    getTypeForBiomarker(observation) {
      if (observation == null) {
        return "";
      }
      if (get(observation, "code.coding[0].code") === "C120465") {
        return "HRD";
      } else if (get(observation, "code.coding[0].code") === "94076-7") {
        return "TMB";
      }
    },

    getValueForBiomarker(observation) {
      const type = this.getTypeForBiomarker(observation);
      if (type === "TMB") {
        const valueQuantity = get(observation, "valueQuantity.value");
        const interpretation = get(observation, "interpretation[0].coding[0].display");

        let tmb = "";
        if (valueQuantity != null) {
          tmb += valueQuantity;
        }
        if (interpretation != null) {
          tmb += "(" + interpretation + ")";
        }
        return tmb;
      } else if (type === "HRD") {
        return observation.valueInteger;
      }
    },

    async addBiomarker() {
      if (!this.$refs.formbiomarker.checkValidity()) {
        this.$refs.formbiomarker.reportValidity();
        return;
      } else {
        this.$emit("addBiomarker", this.newType, this.newDate, this.newValue);
        this.newType = null;
        this.newDate = null;
        this.newValue = null;
      }
    },

    async deleteBiomarker(observation) {
      const answer = await this.$bvModal.msgBoxConfirm(this.$t("reallyDelete"), {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("yes"),
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        this.$emit("deleteBiomarker", observation);
      }
    },

    async uploadImport() {
      try {
        this.loadingImport = true;
        const url = this.dataImporterUrl;
        let file = null;
        if (this.importFiles.length === 1 && this.importFiles[0].type.match(/.*zip.*/)) {
          file = this.importFiles[0];
        } else {
          file = await this.createzipFile(this.importFiles);
        }
        await importMolecularReport(url, file, this.episodeOfCareId, this.token);
        this.$emit("reload");
      } catch (e) {
        let message = "" + e;
        if (e != null && e.response != null && e.response.data != null) {
          message += " Details: " + JSON.stringify(e.response.data);
        }
        this.$emit("error", message);
        this.loadingImport = false;
      }
    },

    async createzipFile(files) {
      console.log("Creating .zip file...");
      const zip = new JSZip();

      files.forEach(f => zip.file(f.name, f));

      const blob = await zip.generateAsync({
        compression: "DEFLATE",
        type: "blob"
      });

      return new File([blob], "upload.zip", {
        type: "application/zip"
      });
    }
  },

  async created() {
    try {
      if (this.localImportantVariants == null && this.reportId != null && this.patientId != null) {
        let importantVariants = cloneDeep(importantVariantsTemplate);
        importantVariants.extension[0].valueReference = this.reportId;
        importantVariants.subject.reference = "Patient/" + this.patientId;
        const response = await submitResource(this.fhirBaseUrl, importantVariants, this.token);
        this.localImportantVariants = response.data;
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$emit("error", e);
    }
  },

  components: {
    DeleteIcon,
    Spinner
  }
};
</script>

<style lang="scss" scoped></style>
