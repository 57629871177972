<template>
  <div @click="$emit('click')" class="card conference-card" :style="{ background: 'linear-gradient(45deg, white 90%, ' + color + ' 90%, ' + color + ')', border: color + ' 1px solid' }">
    <div class="headline">
      <div v-if="conference.tumorConference" class="mr-3">{{ conference.tumorConference.description }}</div>
      <div v-if="!conference.tumorConference" class="mr-3">{{ conference.description }}</div>
      <div v-if="!unlimited && showDate && conference.tumorConference && conference.tumorConference.date" class="conference-date text-muted">
        <span v-if="showDaily">{{ $t("daily") }},</span> {{ $d(new Date(conference.tumorConference.date), dateFormat) }}
      </div>
    </div>
    <div class="spacer" />
    <div class="conference-card-footer text-muted">
      <div class="icon">
        <slot name="icon" />
      </div>
      <div class="cases" v-if="false">
        <div class="count" v-if="conference.tumorConference">{{ conference.tumorConference.entries.length }}</div>
        <div v-if="conference.tumorConference">{{ $tc("planner.casesAssigned", conference.tumorConference.entries.length) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    conference: {
      type: Object,
      required: true
    },

    showEntries: {
      type: Boolean,
      default: true
    },

    showDate: {
      type: Boolean,
      default: true
    },

    showDaily: {
      type: Boolean,
      default: false
    },

    dateFormat: {
      type: String,
      default: "long"
    }
  },

  computed: {
    color() {
      if (this.conference != null && this.conference.videoRoomSettings != null) {
        return this.conference.videoRoomSettings.color;
      }
      return null;
    },

    unlimited() {
      if (this.conference != null && this.conference.videoRoomSettings != null) {
        return this.conference.videoRoomSettings.unlimited;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.conference-card {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  cursor: pointer;
  background: white;
  min-height: 8rem;

  .icon {
    // color: map-get($theme-colors, "primary");
    font-size: 2.25rem;
  }

  .headline {
    font-weight: bold;
    font-size: initial;
    // color: map-get($theme-colors, "primary");
  }

  .conference-date {
    font-weight: 500;
  }

  .conference-card-footer {
    display: flex;

    .icon {
      align-self: flex-end;
      font-size: 1.5rem;
    }

    .cases {
      text-align: right;
      flex: 1;

      // color: map-get($theme-colors, "primary");
      .count {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }
}
</style>
