<template>
  <div class="home">
    <div class="container-fluid info-header" v-if="demo">
      <i18n path="demo.welcome">
        <a href="https://molit.eu/projekte/vitu/" place="url" target="_blank">molit.eu/projekte/vitu</a>
      </i18n>
    </div>
    <div class="container-fluid">
      <div class="grid">
        <link-tile data-cy="tile-worklist" v-if="isModerator || isCaseManager" :title="$tc('worklist.worklist', 1)" class="link-tile" route="worklist">
          <template slot="header">
            <div class="link-tile-icon">
              <clipboard-text-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-worklist" v-if="(isModerator || isCaseManager) && isDockConnect" :title="$t('ownCases')" class="link-tile" route="own-cases">
          <template slot="header">
            <div class="link-tile-icon">
              <bank-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-planner" v-if="isModerator" :title="$tc('planner.conferencePlanner', 1)" class="link-tile" route="planner">
          <template slot="header">
            <div class="link-tile-icon">
              <calendar-range-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-conference" v-if="isParticipant || isModerator" :title="$tc('conference.videoConference', 1)" class="link-tile" route="conference">
          <template slot="header">
            <div class="link-tile-icon">
              <message-video-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile
          data-cy="tile-documentation"
          v-if="isModerator || isCaseManager"
          :title="$tc('documentation.documentation', 1)"
          :class="['link-tile', { disabled: deactivateDocumentation }]"
          :route="deactivateDocumentation ? '' : 'documentation'"
        >
          <template slot="header">
            <div class="link-tile-icon">
              <folder-account-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-cats" v-if="isCaseManager || isModerator" title="CATS" :class="['link-tile']" :route="'cats'">
          <template slot="header">
            <div class="link-tile-icon">
              <img :src="catsLogo" style="vertical-align: initial; height: 4.2rem" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-variant-browser" v-if="isCaseManager || isModerator" title="Variant Browser" :class="['link-tile']" :route="'variant-browser'">
          <template slot="header">
            <div class="link-tile-icon">
              <dna-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-variant-browser" v-if="isCaseManager || isModerator" title="QM Dashboard" :class="['link-tile']" :route="'https://vitu.intern.zvpm.de/qm-dashboard/'" :external="true">
          <template slot="header">
            <div class="link-tile-icon">
              <poll-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-admin-area" v-if="isAdmin" :title="$t('admin.adminArea')" class="link-tile" route="admin">
          <template slot="header">
            <div class="link-tile-icon">
              <wrench-icon title="" />
            </div>
          </template>
        </link-tile>
        <link-tile data-cy="tile-info-page" v-if="showInfoLink" :title="$t('infoPage')" class="link-tile" :route="infoLink" :external="true">
          <template slot="header">
            <div class="link-tile-icon">
              <information-outline-icon title="" />
            </div>
          </template>
        </link-tile>
      </div>
    </div>
  </div>
</template>

<script>
import LinkTile from "@/components/LinkTile";
import DnaIcon from "vue-material-design-icons/Dna";
import PollIcon from "vue-material-design-icons/Poll";
import BankIcon from "vue-material-design-icons/Bank";
import ClipboardTextIcon from "vue-material-design-icons/ClipboardText";
import CalendarRangeIcon from "vue-material-design-icons/CalendarRange";
import MessageVideoIcon from "vue-material-design-icons/MessageVideo";
import FolderAccountIcon from "vue-material-design-icons/FolderAccount";
import InformationOutlineIcon from "vue-material-design-icons/InformationOutline";
import WrenchIcon from "vue-material-design-icons/Wrench";
import logoCats from "@/assets/logo/CATS_Logo_Bild.svg";
import { mapState } from "vuex";

import config from "../config/config";
import roles from "../model/roles";

export default {
  name: "home",

  computed: {
    ...mapState({
      keycloak: state => state.authentication.keycloak,
      roles: state => state.authentication.keycloak.realmAccess.roles
    }),

    catsLogo() {
      return logoCats;
    },

    demo() {
      return config.DEMO;
    },

    deactivateDocumentation() {
      return config.DEACTIVATE_DOCUMENTATION;
    },

    showInfoLink() {
      return config.SHOW_INFO_LINK;
    },

    infoLink() {
      return config.INFO_LINK;
    },

    isDockConnect() {
      return config.DOCK_CONNECT;
    },

    isAdmin() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.ADMINISTRATOR);
      } else {
        return false;
      }
    },

    isModerator() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.MODERATOR);
      } else {
        return false;
      }
    },

    isCaseManager() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.CASE_MANAGER);
      } else {
        return false;
      }
    },

    isFreigeber() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.FREIGEBER);
      } else {
        return false;
      }
    },

    isParticipant() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.USER);
      } else {
        return false;
      }
    }
  },

  components: {
    LinkTile,
    BankIcon,
    DnaIcon,
    PollIcon,
    ClipboardTextIcon,
    CalendarRangeIcon,
    MessageVideoIcon,
    FolderAccountIcon,
    InformationOutlineIcon,
    WrenchIcon
  }
};
</script>

<style lang="scss" scoped>
.home {
  background: linear-gradient($vitu-angle, $vitu-green 0%, $vitu-light-green 100%);
  flex: 1;
  padding-top: 2rem;
}

.link-tile {
  color: $vitu-blue;
  font-size: 1.5rem;
  line-height: 1.15;
  font-weight: 600;
  &.disabled {
    color: rgba(0, 0, 0, 0.5);
    cursor: initial;

    .link-tile-icon {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.info-header {
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.link-tile-icon {
  color: map-get($theme-colors, "primary");
  font-size: 4.4rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 15em));
  grid-gap: 2rem;
  justify-content: center;
}
</style>
