<template>
  <iframe class="iframe" :src="`https://cats.molit.eu`"></iframe>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.iframe {
  min-height: calc(100vh - #{$navbar-height});
  border: 0;
  padding: 0;
  margin: 0;
}
</style>
