<template>
  <div class="documentation-overview-wrapper">
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
    />
    <div class="documentation-overview">
      <div class="container">
        <div class="page-header">
          <h4 class="headline">{{ $t("documentation.documentation") }}</h4>
        </div>
        <div class="main">
          <h5>{{ $t("documentation.existingProtocols") }}</h5>
        </div>
        <!-- <paginated-list
          :fhirBaseUrl="fhirBaseUrl"
          resourceName="Composition"
          :searchParams="searchParams"
          :searchAttributes="searchAttributes"
          :token="token"
          :searchInputPlaceholder="$t('documentation.searchProtocol')"
          @update="onBundleUpdated"
          @error="handlePaginatedError"
        >
          <div v-if="protocols && protocols.length">
            <list-item class="list-item" v-for="protocol in protocols" :key="protocol.id" :title="protocol.subject.display" :subtitle="getSubtitle(protocol)" @click="onClick(protocol)">
              <template slot="icon">
                <file-document-icon class="icon" />
              </template>
            </list-item>
          </div>
          <template slot="firstPage">
            <chevron-double-left-icon />
          </template>
          <template slot="previousPage">
            <chevron-left-icon />
          </template>
          <template slot="nextPage">
            <chevron-right-icon />
          </template>
          <template slot="lastPage">
            <chevron-double-right-icon />
          </template>
        </paginated-list> -->
        <!-- <spinner v-if="loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" /> -->
        <!-- <div v-if="protocols && !protocols.length">{{ $t("documentation.noProtocolsFound") }}</div> -->
        <div class="mt-4">
          <div v-if="!loading">
            <!-- <strong>MTB 26.07.2023</strong> -->

            <div v-for="file in files" :key="file.id">
              <a class="" @click="openDocument(file)" href="javascript:void(null);"><download-icon title="" />{{ file.description }}</a>
            </div>
          </div>
          <spinner v-else size="small" :speed="1.5" />
        </div>
        <!-- <strong>MTB 13.01.2022</strong>
        <ul>
          <li><a href="#">2022-01-13_Empfehlungsprotokoll_MTB_ZvPM_C67_Nachname_Vorname_Einrichtung.docx</a></li>
          <li><a href="#">2022-01-13_Empfehlungsprotokoll_MTB_ZvPM_C67_Nachname_Vorname_Einrichtung.docx</a></li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanels from "@/components/ui/NotificationPanels";
import { mapFhirData } from "@molit/fhir-api";
import config from "@/config/config";
import notifications from "@/mixins/notifications";
import { mapState } from "vuex";

import Spinner from "vue-simple-spinner";
import { fetchResources, mapFhirResponse } from "@molit/fhir-api";

import { authenticatedDownload } from "@/util/util";
import { get } from "lodash";
import moment from "moment";

export default {
  mixins: [notifications],

  data() {
    return {
      error: null,
      showSuccess: false,
      protocols: null,
      searchParams: {
        type: "http://molit.eu/fhir/vitu/CodeSystem/document-type|tumorboard-recommendation-protocol",
        _sort: "-date"
      },
      searchAttributes: [
        {
          name: "Subject",
          value: "subject:Patient.name:contains"
        }
      ],
      loading: false,
      files: null
    };
  },

  computed: {
    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    ...mapState({
      token: state => state.authentication.keycloak.token
    })
  },

  methods: {
    async getFiles() {
      const params = {
        _summary: false,
        _count: 10000,
        _sort: "-date",
        date: "gt" + moment().subtract(20, "d").format("YYYY-MM-DD"),
        type: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl|C25197"
      };
      this.files = mapFhirResponse(await fetchResources(this.fhirBaseUrl, "DocumentReference", params, this.token));
    },

    openDocument(documentReference) {
      const data = get(documentReference, "content[0].attachment.data");
      const location = get(documentReference, "content[0].attachment.url");
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = documentReference.description + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else if (location != null) {
        const title = get(documentReference, "content[0].attachment.title");
        authenticatedDownload(location, title, this.token);
      }
    }
  },

  async mounted() {
    try {
      this.loading = true;
      await this.getFiles();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },

  components: {
    NotificationPanels,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.documentation-overview {
  padding-bottom: 1rem;
}

.main {
  padding-top: 1rem;
}

.documentation-overview-wrapper {
  background: white;
  flex: 1;
}

.list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.8rem;
  padding-bottom: 0.6rem;

  &:last-child {
    border: 0;
  }

  cursor: pointer;
}

.icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
}
</style>
