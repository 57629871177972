<template>
  <div>
    <cancer-print />
  </div>
</template>

<script>
import CancerPrint from "@/components/CancerPrint";

export default {
  components: {
    CancerPrint
  }
};
</script>

<style scoped></style>
