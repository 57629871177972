<template>
  <iframe class="iframe" :src="`https://variant-browser.molit.eu/search${queryString}`"></iframe>
</template>

<script>
export default {
  computed: {
    queryString() {
      if (this.$route.query.q != null) {
        let queryString = `?q=${this.$route.query.q}`;
        if (this.$route.query.ref != null) {
          queryString += `&ref=${this.$route.query.ref}`;
        } else {
          queryString += `&ref=tGRCh37`;
        }
        return queryString;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.iframe {
  min-height: calc(100vh - #{$navbar-height});
  border: 0;
  padding: 0;
  margin: 0;
}
</style>
