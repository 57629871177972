<template>
  <div class="participant-list">
    <div class="list-item">
      <participant-item v-for="participant in sortedParticipants" :key="participant.id" :title="participant.name" :class="{ speaking: participant.speaking }">
        <template #icon>
          <div class="side-nav-collapse-icon">
            <volume-high-icon title="" class="speaking" v-if="participant.speaking" />
            <account-circle-icon title="" v-else />
          </div>
        </template>
        <template #right>
          <b-dropdown variant="link" right no-caret v-if="isModerator">
            <template slot="button-content">
              <dots-vertical-icon title="" class="option-icon" />
            </template>
            <b-dropdown-item @click="$emit('mute', participant.id)" class="text-danger">{{ $t("conference.muteAudio") }}</b-dropdown-item>
          </b-dropdown>
        </template>
      </participant-item>
    </div>
    <div v-if="isAdmin || isModerator" class="list-item">
      <b-button data-cy="saveParticipantsWhoTookPart" @click="saveParticipantsWhoTookPart">
        {{ $t("conference.participants") + ": " + participantsWhoTookPart.length }}
      </b-button>
    </div>
    <b-modal
      data-cy="participants-modal"
      id="participants-modal"
      :title="$t('conference.participantsOfConference')"
      header-text-variant="info"
      :okTitle="$t('conference.participantsDownload')"
      okVariant="info"
      :cancelTitle="$t('cancel')"
      scrollable
      @ok="downloadParticipants()"
    >
      <!-- {{ $t("conference.participantsWhoTookPart") }} -->
      <li v-for="(user, index) in participantsWhoTookPart" :key="index">
        {{ user.name }}
      </li>
    </b-modal>
  </div>
</template>

<script>
import ParticipantItem from "./ParticipantItem";
import AccountCircleIcon from "vue-material-design-icons/AccountCircle";
import DotsVerticalIcon from "vue-material-design-icons/DotsVertical";
import VolumeHighIcon from "vue-material-design-icons/VolumeHigh";
import roles from "@/model/roles";
import { mapState } from "vuex";

export default {
  data() {
    return {
      participantsWhoTookPart: []
    };
  },

  props: {
    participants: {
      type: Array,
      default() {
        return [];
      }
    },

    roomName: {
      type: String
    }
  },

  computed: {
    sortedParticipants() {
      if (!this.participants) {
        return [];
      }
      return this.participants
        .slice()
        .sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return 0;
        })
        .map((p, i) => {
          p.id = p.userId + "-" + i;
          return p;
        });
    },

    ...mapState({
      keycloak: state => state.authentication.keycloak
    }),

    isModerator() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.MODERATOR);
      } else {
        return false;
      }
    },

    isAdmin() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.ADMINISTRATOR);
      } else {
        return false;
      }
    }
  },

  components: {
    AccountCircleIcon,
    DotsVerticalIcon,
    ParticipantItem,
    VolumeHighIcon
  },

  methods: {
    saveParticipantsWhoTookPart() {
      this.$bvModal.show("participants-modal");
    },
    downloadParticipants() {
      console.log(this.participantsWhoTookPart);
      let names = this.participantsWhoTookPart.map(participant => participant.name);
      let namesString = "";
      names.forEach(name => (namesString += name + "\n"));
      console.log(names);
      console.log(namesString);
      const url = window.URL.createObjectURL(new Blob([namesString]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.$t("conference.participants") + " - " + this.roomName + " - " + this.$d(new Date(), "long") + ".txt");
      document.body.appendChild(link);
      link.click();
    }
  },

  watch: {
    participants(newValue) {
      let newParticipants = structuredClone(newValue);
      newParticipants.forEach(participant => {
        const isTracked = !this.participantsWhoTookPart.some(item => item.userId === participant.userId);
        if (isTracked) {
          this.participantsWhoTookPart.push(participant);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.participant-list {
  width: 100%;
  height: 100%;
  padding: 15px;
  right: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.list-item {
  margin: 10px;
}

.speaking {
  color: $vitu-green;
}
</style>
