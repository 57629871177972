<template>
  <div>
    <notification-panels :showError="showError" :errorMessage="error" :showSuccess="showSuccess" :successMessage="success" @closeSuccess="closeSuccess" @closeError="closeError" />
    <div class="container">
      <breadcrumps :breadcrumps="[{ name: $t('admin.adminArea'), route: { name: 'admin' } }, { name: $t('admin.userList'), route: { name: 'users' } }, { name: $t('admin.user') }]" />
      <div class="page-header">
        <h5 v-if="!isExistingUser">{{ $t("admin.addUser") }}</h5>
        <h5 v-else>{{ $t("admin.editUser") }}</h5>
        <div class="spacer"></div>
        <div data-cy="editIcon" v-if="mode !== 'edit' && mode !== 'new'" class="btn" v-on:click="enterEditMode()">
          <edit-icon class="icon"></edit-icon>
        </div>
      </div>
      <div class="page-body">
        <div v-if="user && !saving">
          <form @submit.prevent="save" ref="form" autocomplete="off">
            <div class="pt-3" v-if="user.fromFederation">
              <form-information :text="$t('userFromFederationInfo')" />
              <hr />
            </div>
            <fieldset :disabled="user.fromFederation">
              <legend>{{ $t("admin.account") }}</legend>
              <div class="form-group row">
                <label for="firstName" class="col-md-3 col-form-label">{{ $t("firstName") }}*</label>
                <div class="col-md-9">
                  <input
                    :readonly="mode !== 'edit' && mode !== 'new'"
                    data-cy="firstname"
                    type="text"
                    class="form-control"
                    id="firstName"
                    required
                    :placeholder="$t('firstName')"
                    v-model="user.firstName"
                    @invalid="isEmpty"
                    @input="onChange"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="lastName" class="col-md-3 col-form-label">{{ $t("lastName") }}*</label>
                <div class="col-md-9">
                  <input :readonly="mode !== 'edit' && mode !== 'new'" data-cy="lastname" type="text" class="form-control" id="lastName" required :placeholder="$t('lastName')" v-model="user.lastName" @invalid="isEmpty" @input="onChange" />
                </div>
              </div>
              <div class="form-group row">
                <label for="username" class="col-md-3 col-form-label">{{ $t("username") }}*</label>
                <div class="col-md-9">
                  <input
                    :readonly="mode !== 'edit' && mode !== 'new'"
                    data-cy="username"
                    type="text"
                    class="form-control"
                    id="username"
                    required
                    :placeholder="$t('username')"
                    v-model="user.username"
                    :disabled="isExistingUser"
                    @invalid="isEmpty"
                    @input="onChange"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="email" class="col-md-3 col-form-label">{{ $t("email") }}*</label>
                <div class="col-md-9">
                  <input
                    :readonly="mode !== 'edit' && mode !== 'new'"
                    data-cy="email"
                    type="email"
                    class="form-control"
                    id="email"
                    required
                    :placeholder="$t('email')"
                    v-model="user.email"
                    @invalid="invalidEmail"
                    @change="onChange"
                    oninput="this.setCustomValidity(' ')"
                    ref="email"
                  />
                </div>
              </div>

              <div class="form-group row" v-if="!user.fromFederation">
                <label for="organization" class="col-md-3 col-form-label">{{ $t("organization") }}</label>
                <div class="col-md-9" data-cy="organization.wrapper">
                  <div v-if="mode !== 'edit' && mode !== 'new'">
                    <input v-if="organization && organization.name" readonly data-cy="organization" class="form-control" :value="organization.name" />
                    <input v-else readonly data-cy="organization" class="form-control" :placeholder="$t('organization')" />
                  </div>
                  <div v-else>
                    <div>
                      <resource-select
                        dataCy="organization"
                        :fhirBaseUrl="fhirBaseUrl"
                        :resourceName="'Organization'"
                        :titleAttribute="organizationSelector.titleAttribute"
                        :subtitleAttributes="organizationSelector.subtitleAttributes"
                        :searchAttributes="organizationSelector.searchAttributes"
                        :queryParams="organizationSelector.queryParams"
                        :searchInputPlaceholder="$t('search')"
                        :token="token"
                        :required="false"
                        @error="handleError"
                        v-model="organization"
                        :value="{ name: user.company }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>{{ $t("password") }}</legend>
              <div class="form-group row" v-if="isExistingUser">
                <div class="col-form-label col-md-3 pt-0"></div>
                <div class="col-md-9">
                  <div class="form-check">
                    <input data-cy="checkbox-password" class="form-check-input" type="checkbox" id="set-password" v-model="setPassword" :disabled="user.fromFederation || (mode !== 'edit' && mode !== 'new')" />
                    <label class="form-check-label" for="set-password">{{ $t("setNewPassword") }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-form-label col-md-3 pt-0">{{ $t("hint") }}</div>
                <div :class="['col-md-9', { 'text-muted': !setPassword }]">{{ $t("hintTemporaryPassword") }}</div>
              </div>
              <div class="form-group row">
                <label for="password" class="col-md-3 col-form-label">{{ $t("password") }}*</label>
                <div class="col-md-9">
                  <input
                    :readonly="mode !== 'edit' && mode !== 'new'"
                    data-cy="password"
                    autocomplete="new-password"
                    type="password"
                    :disabled="!setPassword"
                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                    class="form-control"
                    id="password"
                    required
                    @invalid="invalidPassword"
                    @change="onChange"
                    oninput="this.setCustomValidity(' ')"
                    :placeholder="$t('password')"
                    v-model="password"
                  />
                  <small class="form-text text-muted">{{ $t("passwordCriteria") }}</small>
                </div>
              </div>
              <div class="form-group row">
                <label for="password-confirmation" class="col-md-3 col-form-label">{{ $t("passwordConfirmation") }}*</label>
                <div class="col-md-9">
                  <input
                    :readonly="mode !== 'edit' && mode !== 'new'"
                    data-cy="password.repeat"
                    autocomplete="new-password"
                    type="password"
                    :disabled="!setPassword"
                    ref="passwordConfirm"
                    class="form-control"
                    id="password-confirmation"
                    required
                    :placeholder="$t('passwordConfirmation')"
                    v-model="passwordConfirmation"
                    oninput="this.setCustomValidity(' ')"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>{{ $t("roles.roles") }}</legend>
              <div class="form-group row">
                <div class="col-form-label col-md-3 pt-0">{{ $t("roles.roles") }}</div>
                <div class="col-md-9">
                  <div class="form-check" v-for="role in roles" :key="role.id">
                    <input :disabled="mode !== 'edit' && mode !== 'new'" :data-cy="'checkbox.' + role.name" class="form-check-input" type="checkbox" :id="role.id" :value="role" v-model="user.realmRoles" />
                    <label :data-cy="role.name" class="form-check-label" :for="role.id">{{ role.name }}</label>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <spinner class="spinner" v-if="loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
      </div>
      <div class="page-footer" v-if="mode === 'edit' || mode === 'new'">
        <div class="spacer"></div>
        <button data-cy="cancel" class="btn btn-secondary btn-cancel" @click="showCancelModal()">{{ $t("cancel") }}</button>
        <button data-cy="save" class="btn btn-primary" @click="onSubmit" :disabled="saveButtonDisabled">{{ $t("admin.save") }}</button>
      </div>
    </div>
    <b-modal
      data-cy="cancel-modal"
      id="cancel-modal"
      okVariant="danger"
      :title="$t(mode === 'edit' ? 'cancelEditYes' : 'cancelCreateYes')"
      header-text-variant="danger"
      :okTitle="$t(mode === 'edit' ? 'cancelEditYes' : 'cancelCreateYes')"
      :cancelTitle="$t('cancel')"
      @ok="cancel()"
    >
      {{ $t(mode === "edit" ? "cancelEditNote" : "cancelCreateNote") }}
    </b-modal>
  </div>
</template>

<script>
import editIcon from "vue-material-design-icons/Pencil";
import { mapState } from "vuex";
import { addUser, updateUser, getUserById, getRoles, resetPassword, deleteRolesFromUser } from "@/api/security-api";
import Spinner from "vue-simple-spinner";
import notifications from "@/mixins/notifications";
import ResourceSelect from "@/components/ui/ResourceSelect";
import config from "@/config/config";
import { differenceBy } from "lodash";
import Breadcrumps from "@/components/ui/Breadcrumps";
import FormInformation from "@/components/ui/FormInformation";
import NotificationPanels from "@/components/ui/NotificationPanels";

export default {
  mixins: [notifications],

  data() {
    return {
      mode: null,
      organizationSelector: {
        searchAttributes: [
          {
            name: "Name",
            value: "name:contains"
          }
        ],
        queryParams: {
          _sort: "name",
          active: true
        },
        titleAttribute: {
          value: "name",
          type: "sring"
        },
        subtitleAttributes: [
          {
            name: "",
            value: "",
            type: "string"
          }
        ]
      },
      organization: { name: null },
      roles: null,
      user: null,
      backupUser: null,
      password: null,
      passwordConfirmation: null,
      changed: false,
      setPassword: false,
      saving: false
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token
    }),
    fhirBaseUrl() {
      return config.FHIR_URL;
    },
    isExistingUser() {
      return this.$route.params.id && this.$route.params.id !== "new";
    },

    saveButtonDisabled() {
      return false;
    },

    loading() {
      return (!this.user && !this.error) || this.saving;
    }
  },

  methods: {
    showCancelModal() {
      this.$bvModal.show("cancel-modal");
    },
    cancel() {
      if (this.$route.params.id === "new") {
        console.log("new");
        this.$router.push({ name: "users" });
      } else {
        this.$router.replace({ name: "user-edit", params: { id: this.user.id } });
        this.mode = null;
        this.user = structuredClone(this.backupUser);
        if (this.user.company) {
          this.organization.name = this.user.company;
        }
        this.setPassword = false;
      }
    },

    handleKeycloakError(e) {
      if (e && e.response && e.response.data && e.response.data.errorMessage && e.response.data.errorMessage.includes("same username")) {
        this.error = this.$t("admin.usernameAlreadyPresent", { username: this.user.username });
        window.scrollTo(0, 0);
      } else if (e && e.response && e.response.data && e.response.data.errorMessage && e.response.data.errorMessage.includes("same email")) {
        this.error = this.$t("admin.emailAlreadyPresent", { email: this.user.email });
        window.scrollTo(0, 0);
      } else {
        this.handleError(e, this);
      }
    },

    async save() {
      try {
        this.saving = true;
        if (this.isExistingUser) {
          const rolesToRemove = differenceBy(this.roles, this.user.realmRoles, "id");
          if (rolesToRemove && rolesToRemove.length) {
            await deleteRolesFromUser(this.token, this.user.id, rolesToRemove);
          }
          if (this.organization) {
            this.user.organizationId = this.organization.id;
            this.user.company = this.organization.name;
          } else {
            this.user.organizationId = null;
            this.user.company = null;
          }
          if (this.setPassword) {
            await resetPassword(this.user, this.password, this.token);
          }
          let userCopy = structuredClone(this.user);
          if (userCopy.realmRoles) {
            userCopy.realmRoles = userCopy.realmRoles.map(r => r.name);
          }
          await updateUser(userCopy, this.token);
          this.mode = null;
          this.showSuccess = true;
          this.success = this.$t("admin.updatedUserSuccessfully");
          this.$router.replace({ name: "user-edit", params: { id: this.user.id } });
        } else {
          if (this.setPassword) {
            this.user.credentials = [
              {
                temporary: true,
                value: this.password,
                type: "password"
              }
            ];
          }
          if (this.organization) {
            this.user.organizationId = this.organization.id;
            this.user.company = this.organization.name;
          } else {
            this.user.organizationId = null;
            this.user.company = null;
          }
          await addUser(this.user, this.token);
          this.$router.push({ name: "users", query: { success: "admin.createUserSuccessful" } });
        }
        this.setPassword = false;
        this.saving = false;
      } catch (e) {
        this.handleKeycloakError(e);
        this.saving = false;
      }
    },

    isEmpty(event) {
      event.target.setCustomValidity(this.$t("validationMsg.empty"));
    },
    invalidEmail() {
      this.$refs.email.setCustomValidity(this.$t("validationMsg.email"));
    },
    invalidPassword(event) {
      event.target.setCustomValidity(this.$t("validationMsg.password"));
    },
    onChange(event) {
      event.target.setCustomValidity("");
    },

    mapRoles(roles) {
      if (!roles) {
        return [];
      }
      return roles.map(r => {
        return {
          id: r.id,
          name: r.name
        };
      });
    },

    onSubmit() {
      if (this.setPassword) {
        if (this.passwordConfirmation !== this.password) {
          this.$refs.passwordConfirm.setCustomValidity(this.$t("passwordsNotMatching"));
        } else {
          this.$refs.passwordConfirm.setCustomValidity("");
        }
      }

      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }

      this.save();
    },

    enterEditMode() {
      this.mode = "edit";
      this.$router.replace({ name: "user-edit", params: { id: this.user.id }, query: { mode: "edit" } });
    }
  },

  async mounted() {
    if (!this.isExistingUser) {
      this.setPassword = true;
    }
    if (this.$route.query.mode === "edit") {
      this.mode = "edit";
    }

    try {
      this.roles = this.mapRoles((await getRoles(this.token)).data);
    } catch (e) {
      this.handleError(e, this);
    }

    if (this.isExistingUser) {
      try {
        this.user = (await getUserById(this.$route.params.id, this.token)).data;
        this.user.realmRoles = this.mapRoles(this.user.realmRoles);
        if (this.user.company && this.organization) {
          this.organization.name = this.user.company;
        }
      } catch (e) {
        this.handleError(e, this);
      }
    } else {
      this.mode = "new";
      this.user = {
        username: null,
        firstName: null,
        lastName: null,
        organizationId: null,
        realmRoles: [],
        email: null,
        emailVerified: true
      };
    }

    this.backupUser = structuredClone(this.user);
  },

  components: {
    Breadcrumps,
    FormInformation,
    NotificationPanels,
    Spinner,
    ResourceSelect,
    editIcon
  }
};
</script>

<style lang="scss" scoped>
.active-container {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-top: 0.5rem;
}

.btn-cancel {
  margin-right: 0.5rem;
}

fieldset {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;

  legend {
    font-size: 1.3rem;
    padding-top: 1rem;
  }
}

.page-footer {
  border: 0;
  padding-top: 0;
}

.page-body {
  padding-top: 0;
}

.spinner {
  margin-top: 1rem;
}
</style>
