<template>
  <div>
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
    />
    <div class="container">
      <breadcrumps
        :breadcrumps="[
          { name: $tc('worklist.patient', 2), route: { name: 'patients' } },
          { name: $t('worklist.patientDetails'), route: { name: 'patient' } }
        ]"
      />
      <spinner class="spinner" v-if="spinner.loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
      <div class="page-header" v-if="!spinner.loading">
        <h5 class="headline">{{ $tc("worklist.patient", 1) }}</h5>
        <div class="spacer"></div>
        <router-link data-cy="editIcon" v-if="patient" class="btn" :to="{ name: 'patient-edit', params: { id: patient.id }, query: { redirect: 'patient' } }"><edit-icon class="icon"></edit-icon></router-link>
      </div>
      <div class="page-body" v-if="!spinner.loading">
        <div class="main">
          <form autocomplete="off" ref="form" v-on:submit.prevent="onSubmit" v-if="!spinner.loading">
            <fieldset>
              <legend>{{ $t("masterData") }}</legend>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="patientId">{{ $t("patientId") }}</label>
                <div class="col-md-9">
                  <div>
                    <input data-cy="patientId" type="text" class="form-control" :placeholder="$t('patientId')" id="patientId" disabled v-if="patient" v-model="patientId" />
                  </div>
                  <small class="form-text text-muted">{{ $t("patientIdInfo") }}</small>
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="organization">{{ $t("organization") }}</label>
                <div class="col-md-9">
                  <input data-cy="organization" disabled id="organization" type="text" class="form-control" :placeholder="$t('organization')" :value="organization" />
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="firstName">{{ $t("firstName") }}</label>
                <div class="col-md-9">
                  <div>
                    <input data-cy="firstname" disabled id="firstName" type="text" class="form-control" :placeholder="$t('firstName')" v-if="patient && patient.name" v-model="patient.name[0].given[0]" />
                  </div>
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="lastName">{{ $t("lastName") }}</label>
                <div class="col-md-9">
                  <div>
                    <input data-cy="lastname" v-if="patient && patient.name" disabled id="lastName" type="text" class="form-control" :placeholder="$t('lastName')" v-model="patient.name[0].family" />
                  </div>
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="birthDate">{{ $t("worklist.birthDate") }}</label>
                <div class="col-md-3">
                  <div>
                    <input data-cy="birthDate" v-if="patient && patient.birthDate" disabled id="birthDate" type="text" class="form-control" :placeholder="$t('worklist.birthDate')" v-model="birthDate" />
                  </div>
                </div>
                <label class="col-md-3 col-form-label" for="gender">{{ $t("sex") }}</label>
                <div class="col-md-3">
                  <select data-cy="gender" disabled id="gender" type="date" class="form-control" :placeholder="$t('sex')" v-model="patient.gender" v-on:keyup.enter="onSubmit">
                    <option selected disabled :value="null">{{ $t("pleaseSelect") }}</option>
                    <option value="male">{{ $t("male") }}</option>
                    <option value="female">{{ $t("female") }}</option>
                    <option value="other">{{ $t("diverse") }}</option>
                    <option value="unknown">{{ $t("unknown") }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="lastName">{{ $t("worklist.caseId") }}</label>
                <div class="col-md-9">
                  <input data-cy="caseId" disabled id="caseId" type="text" class="form-control" :placeholder="$t('worklist.caseId')" v-model="caseId" v-on:keyup.enter="onSubmit" />
                  <small class="form-text text-muted">{{ $t("caseIdInfo") }}</small>
                </div>
              </div>
              <hr />
            </fieldset>
            <fieldset>
              <legend>{{ $t("patientAddress") }}</legend>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="street">{{ $t("street") }}</label>
                <div class="col-md-9">
                  <input data-cy="street" disabled id="street" type="text" class="form-control" :placeholder="$t('street')" v-model="address.street" v-on:keyup.enter="onSubmit" />
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="houseNumber">{{ $t("houseNumber") }}</label>
                <div class="col-md-9">
                  <input data-cy="houseNumber" disabled id="houseNumber" type="text" class="form-control" v-model="address.houseNumber" :placeholder="$t('houseNumber')" v-on:keyup.enter="onSubmit" />
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="postalCode">{{ $t("postalCode") }}</label>
                <div class="col-md-9">
                  <input data-cy="postalCode" disabled id="postalCode" type="text" class="form-control" v-model="address.postalCode" :placeholder="$t('postalCode')" v-on:keyup.enter="onSubmit" />
                </div>
              </div>
              <div class="form-group form-row">
                <label class="col-md-3 col-form-label" for="city">{{ $t("city") }}</label>
                <div class="col-md-9">
                  <input data-cy="city" disabled id="city" type="text" class="form-control" v-model="address.city" :placeholder="$t('city')" v-on:keyup.enter="onSubmit" />
                </div>
              </div>
            </fieldset>
            <div v-if="!spinner.loading && patient">
              <div v-if="showCaseRegistrationButton">
                <div class="page-footer">
                  <div class="spacer"></div>
                  <router-link class="btn btn-primary" :to="{ name: 'clinical-case-new', query: { patientId: patient.id } }">{{ $t("addCaseInformation") }}</router-link>
                </div>
              </div>
            </div>
            <hr />
            <fieldset style="margin-top: 1rem">
              <legend>{{ $t("patientConsent.consentTitle") }}</legend>
              <div class="information-icon-wrapper" v-if="datenspendeEnabled === 'true' && consent === null">
                <div><information-outline-icon class="information-icon" /></div>
                <i18n path="patientConsent.support" tag="div">
                  <template #br>
                    <br />
                  </template>
                </i18n>
              </div>
              <div class="consent-form" v-if="consent !== null">
                <h6>
                  {{ $t("patientConsent.datenspende") }}
                  <button type="button" class="btn btn-link my-btn-link" @click="showMore.datenspende = !showMore.datenspende">
                    <a v-if="!showMore.datenspende">{{ $t("patientConsent.showMore") }}</a>
                    <a v-if="showMore.datenspende">{{ $t("patientConsent.showLess") }}</a>
                  </button>
                </h6>
                <p v-html="$t('patientConsent.datenspendeDetail')" v-if="showMore.datenspende"></p>
                <p v-if="consent === null" class="consent">{{ $t("patientConsent.noInfo") }}</p>
                <p v-else-if="consent.provision.provision[0].type === 'permit'" class="consent">{{ $t("yes") }}</p>
                <p v-else-if="consent.provision.provision[0].type === 'deny'" class="consent">{{ $t("no") }}</p>
                <h6>
                  {{ $t("patientConsent.kontaktaufnahme_Zusatzbefunde") }}
                  <button type="button" class="btn btn-link my-btn-link" @click="showMore.kontaktaufnahme_Zusatzbefunde = !showMore.kontaktaufnahme_Zusatzbefunde">
                    <a v-if="!showMore.kontaktaufnahme_Zusatzbefunde">{{ $t("patientConsent.showMore") }}</a>
                    <a v-if="showMore.kontaktaufnahme_Zusatzbefunde">{{ $t("patientConsent.showLess") }}</a>
                  </button>
                </h6>
                <p v-html="$t('patientConsent.kontaktaufnahme_Zusatzbefunde_Detail')" v-if="showMore.kontaktaufnahme_Zusatzbefunde"></p>
                <p v-if="consent === null" class="consent">{{ $t("patientConsent.noInfo") }}</p>
                <p v-else-if="consent.provision.provision[2].type === 'permit'" class="consent">{{ $t("yes") }}</p>
                <p v-else-if="consent.provision.provision[2].type === 'deny'" class="consent">{{ $t("no") }}</p>
                <h6>
                  {{ $t("patientConsent.kontaktaufnahme_wissenschaftlich") }}
                  <button type="button" class="btn btn-link my-btn-link" @click="showMore.kontaktaufnahme_wissenschaftlich = !showMore.kontaktaufnahme_wissenschaftlich">
                    <a v-if="!showMore.kontaktaufnahme_wissenschaftlich">{{ $t("patientConsent.showMore") }}</a>
                    <a v-if="showMore.kontaktaufnahme_wissenschaftlich">{{ $t("patientConsent.showLess") }}</a>
                  </button>
                </h6>
                <p v-html="$t('patientConsent.kontaktaufnahme_wissenschaftlich_Detail')" v-if="showMore.kontaktaufnahme_wissenschaftlich"></p>
                <p v-if="consent === null" class="consent">{{ $t("patientConsent.noInfo") }}</p>
                <p v-else-if="consent.provision.provision[1].type === 'permit'" class="consent">{{ $t("yes") }}</p>
                <p v-else-if="consent.provision.provision[1].type === 'deny'" class="consent">{{ $t("no") }}</p>
              </div>
            </fieldset>
          </form>
          <div class="text-right">
            <b-button data-cy="donationButton" class="donation-btn" v-if="datenspendeEnabled === 'true' && consent === null" variant="primary" @click="openDatenspende()">{{ $t("worklist.openDataDonation") }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import editIcon from "vue-material-design-icons/Pencil";
import Breadcrumps from "@/components/ui/Breadcrumps";
import InformationOutlineIcon from "vue-material-design-icons/InformationOutline";
import NotificationPanels from "@/components/ui/NotificationPanels";
import notifications from "@/mixins/notifications";
import { getPatient, addConsentDatenspende, fetchConsentDatenspende } from "@/api/worklist-api";
import Spinner from "vue-simple-spinner";
import config from "@/config/config";
import moment from "moment";
import { mapState } from "vuex";
import roles from "@/model/roles";
import * as fhirApi from "@molit/fhir-api";

export default {
  mixins: [notifications],

  components: {
    editIcon,
    Breadcrumps,
    InformationOutlineIcon,
    NotificationPanels,
    Spinner
  },

  data() {
    return {
      patientId: null,
      birthDate: null,
      patient: null,
      caseId: null,
      showSuccess: false,
      success: null,
      spinner: { loading: false },
      error: null,
      edit: false,
      task: null,
      showCaseRegistrationButton: false,
      address: {
        street: null,
        houseNumber: null,
        postalCode: null,
        city: null
      },
      showMore: {
        datenspende: false,
        kontaktaufnahme_Zusatzbefunde: false,
        kontaktaufnahme_wissenschaftlich: false
      },
      consent: null
    };
  },

  computed: {
    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    ...mapState({
      token: state => state.authentication.keycloak.token,
      keycloak: state => state.authentication.keycloak
    }),

    organization() {
      if (this.patient && this.patient.managingOrganization) {
        return this.patient.managingOrganization.display;
      }
      return "-";
    },
    datenspendeEnabled() {
      return config.DATENSPENDE_ENABLED;
    }
  },

  methods: {
    /**
     * Formats the given Date
     */
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    /**
     * Returns the Identifierobject
     */
    getIdentifier() {
      if (this.patient && this.patient.identifier && this.patient.identifier.length !== 0) {
        for (let i = 0; i < this.patient.identifier.length; i++) {
          if (this.patient.identifier[i].system === "molit/testsystem") {
            return this.patient.identifier[i].value;
          }
        }
      }
      return null;
    },
    openDatenspende() {
      let data = { secret: process.env.VUE_APP_DATENSPENDE_AUTHENTICATION };
      if (this.patientId != null) {
        data = { ...data, patientId: this.patientId };
      }
      if (this.patient.name[0].given[0] != null) {
        data = { ...data, firstname: this.patient.name[0].given[0] };
      }
      if (this.patient.name[0].family != null) {
        data = { ...data, lastname: this.patient.name[0].family };
      }
      if (this.birthDate != null) {
        data = { ...data, birthdate: this.birthDate };
      }
      if (this.patient.gender != null) {
        let g;
        if (this.patient.gender === "female") {
          g = "F";
        } else if (this.patient.gender === "male") {
          g = "M";
        } else if (this.patient.gender === "diverse") {
          g = "D";
        } else if (this.patient.gender === "other") {
          g = "O";
        } else if (this.patient.gender === "unknown") {
          g = "U";
        }
        data = { ...data, gender: g };
      }
      if (this.address.street != null) {
        data = { ...data, street: this.address.street };
      }
      if (this.address.houseNumber != null) {
        data = { ...data, houseNumber: this.address.houseNumber };
      }
      if (this.address.postalCode != null) {
        data = { ...data, postCode: this.address.postalCode };
      }
      if (this.address.city != null) {
        data = { ...data, town: this.address.city };
      }
      if (this.patient.hasOwnProperty("managingOrganization") && this.patient.managingOrganization.reference != null) {
        // let org  = this.patient.managingOrganization.reference.split("/")
        data = { ...data, organisation: this.patient.managingOrganization.display };
      }
      if (this.caseId != null) {
        data = { ...data, caseId: this.caseId };
      }
      data = { ...data, redirectUri: location.protocol + "//" + location.host + location.pathname };

      const searchParams = new URLSearchParams(data);
      window.open(process.env.VUE_APP_DATENSPENDE_BASE_URL + "?" + searchParams.toString(), "_self");
    },

    async saveConsentGivenByQueryParams() {
      this.spinner.message = this.$t("worklist.loadingData");
      let ds = "deny";
      let rfwi = "deny";
      let zb = "deny";

      if (this.$route.query.consentGeneral) {
        if (this.$route.query.consentGeneral === "OPTIN") {
          ds = "permit";
        }
      }
      if (this.$route.query.consentContactFindings) {
        if (this.$route.query.consentContactFindings === "OPTIN") {
          zb = "permit";
        } else {
          zb = "deny";
        }
      }
      if (this.$route.query.consentContactScientific) {
        if (this.$route.query.consentContactScientific === "OPTIN") {
          rfwi = "permit";
        } else {
          rfwi = "deny";
        }
      }
      try {
        let response = await addConsentDatenspende(this.fhirBaseUrl, this.patient.id, ds, rfwi, zb, moment(), this.token);
        this.consent = response.data;
        window.history.replaceState({}, "", location.protocol + "//" + location.host + location.pathname);
      } catch (error) {
        this.spinner.loading = false;
        this.handleError(error);
      }
    }
  },

  async mounted() {
    if (this.$route.query.success) {
      this.showSuccess = true;
      this.success = this.$t(this.$route.query.success);
    }
    // if (this.$route.query.consentGeneral) {
    //   await this.saveConsentGivenByQueryParams();
    // }
  },

  async created() {
    try {
      this.spinner.loading = true;
      let response = await getPatient(this.fhirBaseUrl, this.$route.params.id, {}, this.token);
      this.patient = response.data;
      this.patientId = this.getIdentifier();
      this.birthDate = this.formatDate(this.patient.birthDate);
      let hasNumber = /\d/;
      if (this.patient.address != null) {
        if (this.patient.address[0] != null && this.patient.address[0].line != null) {
          if (this.patient.address[0].line.length === 2) {
            this.address.street = this.patient.address[0].line[0];
            this.address.houseNumber = this.patient.address[0].line[1];
          }
          if (!hasNumber.test(this.patient.address[0].line[0])) {
            this.address.street = this.patient.address[0].line[0];
          }
          if (hasNumber.test(this.patient.address[0].line[0])) {
            this.address.houseNumber = this.patient.address[0].line[0];
          }
        }
        if (this.patient.address[0].postalCode != null) {
          this.address.postalCode = this.patient.address[0].postalCode;
        }
        if (this.patient.address[0].city != null) {
          this.address.city = this.patient.address[0].city;
        }
      }
      let url = this.fhirBaseUrl + "/EpisodeOfCare?patient=Patient/" + this.patient.id;
      let responseEpisodeOfCare = await fhirApi.fetchByUrl(url, null, this.token);
      let entries = responseEpisodeOfCare.data.entry;
      if (entries && entries.length && entries[0].resource && entries[0].resource.identifier) {
        this.caseId = entries[0].resource.identifier[0].value;
      }
      if (this.caseId) {
        this.task = fhirApi.mapFhirResponse(await fhirApi.fetchResources(this.fhirBaseUrl, "Task", { subject: "Patient/" + this.$route.params.id }, this.token))[0];

        if (this.task && this.task.businessStatus && this.task.businessStatus.text === "Neu") {
          this.showCaseRegistrationButton = true;
        }
      }
      if (this.$route.query.consentGeneral) {
        await this.saveConsentGivenByQueryParams();
      } else {
        const consentResponse = await fetchConsentDatenspende(this.fhirBaseUrl, this.token, this.patient.id);
        if (consentResponse.data.hasOwnProperty("entry") && consentResponse.data.entry.length) {
          this.consent = consentResponse.data.entry[0].resource;
        }
      }

      setTimeout(() => {
        this.spinner.loading = false;
      }, 200);
    } catch (error) {
      this.spinner.loading = false;
      this.handleError(error);
    }
  }
};
</script>

<style lang="scss" scoped>
.spinner {
  padding-top: 15px;
}
.icon {
  color: $vitu-green;
}
.donation-btn {
  margin-left: 1rem;
}
.consent-form {
  margin: 0.3rem 1em 1em 1.5rem;
}
.consent {
  margin-left: 2rem;
}
.my-btn-link {
  vertical-align: inherit !important;
  padding: 0;
}

legend {
  font-size: 1.2rem;
}
</style>
