<template>
  <div>
    <notification-panels :showError="showError" :errorMessage="error" :showSuccess="showSuccess" :successMessage="$t('admin.saveSuccessful')" @closeSuccess="closeSuccess" @closeError="closeError" />
    <div class="container">
      <breadcrumps :breadcrumps="[{ name: $t('admin.adminArea'), route: { name: 'admin' } }, { name: $t('admin.conferenceSettings') }]" />
      <div class="page-header">
        <h5 class="headline">{{ $t("admin.conferenceSettings") }}</h5>
        <div class="spacer"></div>
        <div data-cy="editIcon" v-if="mode !== 'edit'" class="btn" v-on:click="enterEditMode()">
          <edit-icon class="icon"></edit-icon>
        </div>
      </div>
      <div class="page-body">
        <spinner v-if="loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
        <div v-if="settings && !loading">
          <form @submit.prevent="save" ref="form" autocomplete="off">
            <div class="form-group row">
              <label for="showVideo" class="col-md-3 col-form-label">{{ $t("showVideo") }}</label>
              <div class="col-md-9 active-container">
                <input :disabled="mode !== 'edit'" data-cy="showVideo" type="checkbox" id="showVideo" v-model="settings.showVideo" />
              </div>
            </div>
            <div class="form-group row">
              <label for="showVideo" class="col-md-3 col-form-label">{{ $t("enableAudioInitially") }}</label>
              <div class="col-md-9 active-container">
                <input :disabled="mode !== 'edit'" data-cy="enableAudioInitially" type="checkbox" id="enableAudioInitially" v-model="settings.enableAudioInitially" />
              </div>
            </div>
            <div class="form-group row">
              <label for="bitrate" class="col-md-3 col-form-label">{{ $t("admin.bitrate") }}</label>
              <div class="col-md-9">
                <select :disabled="mode !== 'edit'" data-cy="bitrate" class="form-control" id="bitrate" v-model="settings.bitrate">
                  <option :data-cy="bitrate.value" v-for="bitrate in bitrates" :key="bitrate.value" :value="bitrate.value">{{ $t(`admin.bitrates.${bitrate.display}`) }} {{ bitrate.value !== 0 ? `(${bitrate.value} kbit/s)` : "" }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="aspect-ratio" class="col-md-3 col-form-label">{{ $t("admin.aspectRatio") }}</label>
              <div class="col-md-9">
                <select :disabled="mode !== 'edit'" data-cy="aspectRatio" class="form-control" id="aspect-ratio" v-model="settings.aspectRatio">
                  <option data-cy="aspectRatio.value" v-for="aspectRatio in aspectRatios" :key="aspectRatio.value" :value="aspectRatio.value">{{ aspectRatio.display }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="cutVideoStreams" class="col-md-3 col-form-label">{{ $t("admin.cutVideoStreams") }}</label>
              <div class="col-md-9 active-container">
                <input :disabled="mode !== 'edit'" data-cy="cutVideoStreams" type="checkbox" id="cutVideoStreams" v-model="settings.cutVideoStreams" />
              </div>
            </div>
            <div class="form-group row">
              <label for="maxNumberOfVideos" class="col-md-3 col-form-label">{{ $t("maxNumberOfVideos") }}</label>
              <div class="col-md-9">
                <input :disabled="mode !== 'edit'" data-cy="maxNumberOfVideos" type="number" min="0" step="1" class="form-control" id="maxNumberOfVideos" :placeholder="$t('maxNumberOfVideos')" v-model.number="settings.maxNumberOfVideos" />
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <label for="persistentRoomEnabled" class="col-md-3 col-form-label">{{ $t("admin.persistentRoomEnabled") }}</label>
              <div class="col-md-9 active-container">
                <input :disabled="mode !== 'edit'" data-cy="persistentRoomEnabled" type="checkbox" id="persistentRoomEnabled" v-model="settings.persistentRoomEnabled" />
              </div>
            </div>
            <div class="form-group row">
              <label for="persistentRoomName" class="col-md-3 col-form-label">{{ $t("admin.persistentRoomName") }}</label>
              <div class="col-md-9">
                <input
                  :disabled="mode !== 'edit'"
                  data-cy="persistentRoomName"
                  type="text"
                  :maxlength="maxLengthConferenceName"
                  class="form-control"
                  id="persistentRoomName"
                  :placeholder="$t('admin.persistentRoomName')"
                  v-model="settings.persistentRoomName"
                />
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <label for="showDateTimeInTitle" class="col-md-3 col-form-label">{{ $t("admin.showDateTimeInTitle") }}</label>
              <div class="col-md-9 active-container">
                <input :disabled="mode !== 'edit'" data-cy="showDateTimeInTitle" type="checkbox" id="showDateTimeInTitle" v-model="settings.showDateTimeInTitle" />
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <label for="turnUrl" class="col-md-3 col-form-label">{{ $t("admin.turnUrl") }}</label>
              <div class="col-md-9">
                <input :disabled="mode !== 'edit'" data-cy="turnUrl" type="text" class="form-control" id="turnUrl" :placeholder="$t('admin.turnUrl')" v-model="settings.turnUrl" />
              </div>
            </div>
            <div class="form-group row">
              <label for="turnUser" class="col-md-3 col-form-label">{{ $t("admin.turnUser") }}</label>
              <div class="col-md-9">
                <input :disabled="mode !== 'edit'" data-cy="turnUser" type="text" class="form-control" id="turnUser" autocomplete="off" :placeholder="$t('admin.turnUser')" v-model="settings.turnUser" />
              </div>
            </div>
            <div class="form-group row">
              <label for="turnSecret" class="col-md-3 col-form-label">{{ $t("admin.turnSecret") }}</label>
              <div class="col-md-9">
                <input :disabled="mode !== 'edit'" data-cy="turnSecret" type="password" autocomplete="new-password" class="form-control" id="turnSecret" :placeholder="$t('admin.turnSecret')" v-model="settings.turnSecret" />
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <label for="turnSecret" class="col-md-3 col-form-label">{{ $t("admin.createNotification") }}</label>
              <div class="col-md-9">
                <input :disabled="mode !== 'edit'" data-cy="alertText" type="text" class="form-control" id="alertText" placeholder="Meldung" v-model="settings.alertText" />

                <div class="color-row">
                  <div v-for="(btn, index) in colorButtons" :key="`color-btn-${index}`">
                    <label class="color-btn" v-bind:class="{ active: btn.background === selectedColor }" :style="{ background: btn.background }">
                      <input :disabled="mode !== 'edit'" :data-cy="btn.background" type="radio" name="colorButton" :id="btn.background" :value="btn.background" style="opacity: 0" v-model="selectedColor" />
                    </label>
                  </div>
                </div>
              </div>
              <label for="turnSecret" class="col-md-3 col-form-label">{{ $t("admin.showNotification") }}</label>
              <div class="col-md-9" style="padding-top: 8px">
                <input :disabled="mode !== 'edit'" data-cy="alertActive" type="checkbox" id="alertActive" v-model="settings.alertActive" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="page-footer">
        <div class="spacer"></div>
        <button v-if="mode === 'edit'" data-cy="cancel" class="btn btn-secondary btn-cancel spacing" @click="showCancelModal">{{ $t("cancel") }}</button>
        <button v-if="mode === 'edit'" data-cy="save" class="btn btn-primary" @click="onSubmit">{{ $t("admin.save") }}</button>
      </div>
    </div>
    <b-modal data-cy="cancel-modal" id="cancel-modal" okVariant="danger" :title="$t('cancelYes')" header-text-variant="danger" :okTitle="$t('cancelYes')" :cancelTitle="$t('cancel')" @ok="cancel()">
      {{ $t("cancelNote") }}
    </b-modal>
  </div>
</template>

<script>
import editIcon from "vue-material-design-icons/Pencil";
import Breadcrumps from "@/components/ui/Breadcrumps";
import NotificationPanels from "@/components/ui/NotificationPanels";
import config from "@/config/config";
import aspectRatios from "@/model/aspect-ratios";
import bitrates from "@/model/bitrates";
import notifications from "@/mixins/notifications";
import { flatui1 } from "@/model/color-palettes";

import Spinner from "vue-simple-spinner";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [notifications],

  data() {
    return {
      showSuccess: false,
      mode: null,
      loading: false,
      selectedColor: null,
      colorButtons: flatui1,
      oldSettings: {},
      settings: {}
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token,
      roles: state => state.authentication.keycloak.realmAccess.roles,
      savedSettings: state => state.settings.conferenceSettings
    }),

    aspectRatios() {
      return aspectRatios;
    },

    bitrates() {
      return bitrates;
    },

    maxLengthConferenceName() {
      return config.MAX_LENGTH_CONFERENCE_NAME;
    }
  },

  methods: {
    ...mapActions(["updateConferenceSettings"]),

    onSubmit() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }
      this.save();
    },

    enterEditMode() {
      this.mode = "edit";
      this.$router.replace({ name: "conference-settings", query: { mode: "edit" } });
    },

    showCancelModal() {
      this.$bvModal.show("cancel-modal");
    },

    cancel() {
      this.mode = null;
      this.$router.replace({ name: "conference-settings" });
      this.settings = structuredClone(this.oldSettings);
    },

    async save() {
      this.showSuccess = false;
      this.loading = true;
      try {
        let settings = Object.assign({}, config.DEFAULT_CONFERENCE_SETTINGS, this.settings);
        await this.updateConferenceSettings({ token: this.token, settings });
        this.showSuccess = true;
        this.loading = false;
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.showSuccess = false;
        }, config.SUCCESS_HEADER_TIMEOUT);
      } catch (e) {
        this.handleError(e);
        this.loading = false;
      }
      this.mode = null;
      this.$router.replace({ name: "conference-settings" });
    }
  },
  watch: {
    selectedColor() {
      this.colorButtons.forEach(btn => {
        if (btn.background === this.selectedColor) {
          this.settings.alertColor = btn.background;
          this.settings.alertTextColor = btn.color;
        }
      });
    }
  },
  mounted() {
    if (this.$route.query.mode === "edit") {
      this.mode = "edit";
    }
    if (this.settings.hasOwnProperty("alertColor")) {
      this.selectedColor = this.settings.alertColor;
    }
    this.settings = this.savedSettings;
    this.oldSettings = structuredClone(this.settings);
  },
  components: {
    editIcon,
    Breadcrumps,
    NotificationPanels,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.active-container {
  display: flex;
  align-items: center;
}
.color-row {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}
.color-btn {
  width: 24px;
  height: 24px;
  margin: 0.25rem;
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.active {
  opacity: 0.85;
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.2);
}
</style>
