import DOMPurify from "dompurify";
import { customAlphabet } from "nanoid";
import { marked } from "marked";

/**
 * Helper function to stringify the values of an Object.
 *
 * @param value - the value to be stringified
 * @returns {String} - the stringified value
 */
export const toString = value => {
  if (value === null || typeof value === "undefined") {
    return "";
  } else if (value instanceof Object) {
    return Object.keys(value)
      .sort()
      .map(key => toString(value[key]))
      .join(" ");
  } else {
    return String(value);
  }
};

/**
 * Adds a colon to the timezone offset of the given date string.
 *
 * @param {String} dateString - the date in ISO date format
 */
export const addColonToISODateString = dateString => {
  if (!dateString) {
    return dateString;
  }
  //add colon to timezone offset
  return dateString.replace(/([+-]\d\d)(\d\d)$/, "$1:$2");
};

/**
 * Generates an id.
 *
 * @param {Number} [length=8] - the length of the id
 * @param {String} [alphabet="3479ACDEFHKMNPQRTYWXZ"] - the alphabet to be used for id generation
 * @returns {String} - the generated id
 */
export const generateId = (length = 8, alphabet = "3479ACDEFHKMNPQRTYWXZ") => {
  const nanoid = customAlphabet(alphabet, length);
  return nanoid();
};

/**
 * Creates a check digit for the given id and returns it.
 * See: https://wiki.openmrs.org/display/docs/Check+Digit+Algorithm
 *
 * @param {String} id - the id
 * @param {String} [validChars="3479ACDEFHKMNPQRTYWXZ"] - the validChars to be used for check digit generation
 * @returns {Number} - the generated check digit
 */
export const luhnCheckDigit = (id, validChars = "3479ACDEFHKMNPQRTYWXZ") => {
  if (!id) {
    throw new Error("Id must not be null.");
  }
  id = id.toUpperCase().trim();
  let sum = 0;
  for (let i = 0; i < id.length; i++) {
    let ch = id.charAt(id.length - i - 1);
    if (validChars.indexOf(ch) < 0) {
      throw new Error("Invalid character(s) found!");
    }
    let digit = ch.charCodeAt(0) - 48;
    let weight;
    if (i % 2 == 0) {
      weight = 2 * digit - parseInt(digit / 5) * 9;
    } else {
      weight = digit;
    }
    sum += weight;
  }
  sum = Math.abs(sum) + 10;
  let digit = (10 - (sum % 10)) % 10;
  return digit;
};

/**
 * Generates an id with prefix and check digit.
 *
 * @param {String} [prefix="Z"] - the prefix of the id
 * @param {Number} [length=8] -the length of the generated id (excluding prefix and check digit)
 * @param {String} [alphabet="3479ACDEFHKMNPQRTYWXZ"] - the alphabet to be used for id generation
 * @returns {String} - the generated id
 */
export const generateCheckedId = (prefix = "Z", length = 8, alphabet = "3479ACDEFHKMNPQRTYWXZ") => {
  let id = `${prefix}${generateId(length, alphabet)}`;
  id += `${luhnCheckDigit(id, alphabet)}`;
  return id;
};

/**
 * Generates a color based on the given string.
 *
 * @param {String} str - the string
 * @returns {String} - the generated color
 */
export const stringToColor = str => {
  if (str == null) {
    return "#148898";
  }

  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};

/**
 * Creates safe HTML from markdown by using DOMPurify and marked.js.
 *
 * @param {String} markdown - the markdown text
 * @param {boolean} [openLinksInNewTab=false] - whether to open generated links in new tab
 * @returns {String} - the generated HTML
 */
export const markdownToHtml = (markdown, openLinksInNewTab = false) => {
  if (!markdown || typeof markdown !== "string") {
    return "";
  }

  let html = DOMPurify.sanitize(marked(markdown, { sanitize: true }));

  if (openLinksInNewTab) {
    html = html.replace(/<a /g, '<a target="_blank" rel="nofollow" ');
  }

  return html;
};

/**
 * converts a given File into a Base64 string
 *
 * @param {*} file - the file
 */
export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const moveInArray = (array, from, to) => {
  let numberOfDeletedElm = 1;

  const elm = array.splice(from, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  array.splice(to, numberOfDeletedElm, elm);
};

/**
 * Creates an on-the-fly download from any kind of content.
 *
 * @param {*} content - the file content
 * @param {String} fileName - the file name
 * @param {String} contentType - the file content type
 */
export const download = (content, fileName, contentType) => {
  const url = window.URL.createObjectURL(new Blob([content], { type: contentType }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const authenticatedDownload = (url, filename, jwt) => {
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwt
    }
  })
    .then(function (response) {
      return response.arrayBuffer();
    })
    .then(function (data) {
      // create a blob url representing the data
      var blob = new Blob([data]);
      var url = window.URL.createObjectURL(blob);
      // attach blob url to anchor element with download attribute
      var anchor = document.createElement("a");
      anchor.setAttribute("href", url);
      anchor.setAttribute("download", filename);
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
};

/**
 * Deletes all cookies of the current domain.
 */
export const deleteAllCookies = () => {
  const allCookies = document.cookie.split(";");

  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (let i = 0; i < allCookies.length; i++) {
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
  }
};

/**
 * Function to open a video in a new tab
 * @param {*} stream - the selected stream
 * @param {String} caption - the caption which is used for the tab title
 */
export const openVideoInNewTab = (stream, caption) => {
  let newTab = window.open("", "_blank");
  newTab.document.write('<body style="background-color:black; margin: 0 auto; display:flex; justify-content:center; height: 100%; width: 100%"></body>');
  newTab.document.title = caption;

  let videoElement = document.createElement("VIDEO");
  videoElement.autoplay = true;
  videoElement.muted = true;
  let duplicatedVideo = newTab.document.body.appendChild(videoElement);
  duplicatedVideo.srcObject = stream;
};
