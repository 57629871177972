<template>
  <div>
    <div>
      <spinner v-if="loading && !error" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
      <form ref="form" autocomplete="off" v-on:submit.prevent="save">
        <div v-if="!loading && !error">
          <fieldset>
            <table class="table table-bordered" style="table-layout: fixed">
              <colgroup>
                <col style="width: 80px" />
                <col style="width: 70%" />
                <col style="width: 240px" />
                <col style="width: 30%" />
                <col style="width: 128px" />
                <col style="width: 96px" v-if="editing" />
              </colgroup>
              <thead>
                <tr class="row-header">
                  <th>{{ $t("prio") }}</th>
                  <th>{{ $t("documentation.therapyRecommendation") }}</th>
                  <th>{{ $t("treatmentContext") }}</th>
                  <th>{{ $t("recommendationCategory") }}</th>
                  <th>{{ $t("evidenceLevel") }}</th>
                  <th v-if="editing"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!recommendations || !recommendations.length">
                  <td colspan="5" class="text-center">{{ $t("noEntriesFound") }}</td>
                </tr>
              </tbody>
              <tbody v-for="(recommendation, index) in recommendations" :key="index">
                <tr>
                  <td>
                    <input class="form-control" required type="number" min="1" v-model="recommendation.orderNumber" v-if="editing" /><span v-if="!editing">{{ recommendation.orderNumber }}</span>
                  </td>
                  <td>
                    <concept-select
                      v-if="editing"
                      class="multiple"
                      :fhirBaseUrl="fhirBaseUrl"
                      resourceName="ValueSet"
                      url="http://molit.eu/fhir/ValueSet/substances-protocols"
                      sort
                      :disabled="!editing"
                      multiple
                      :searchInputPlaceholder="$t('search')"
                      :token="token"
                      @error="handleError"
                      mapToConceptMap
                      conceptMapUrl="http://molit.eu/fhir/vitu/ConceptMap/protocols-to-atc"
                      :sortFunction="medicationSortFunction"
                      v-model="recommendation.medication"
                    ></concept-select>
                    <span v-if="!editing && recommendation != null && recommendation.medication != null">{{
                      recommendation.medication.reduce((acc, c) => {
                        return acc + " " + c.display;
                      }, "")
                    }}</span>
                  </td>
                  <td>
                    <v-select :options="instantOfTimeList" label="display" :placeholder="$t('pleaseSelect')" v-model="recommendation.status" v-if="editing" /><span v-if="!editing && recommendation.status">{{
                      recommendation.status.display
                    }}</span>
                  </td>
                  <td>
                    <v-select :options="[...recommendationCategoryList]" label="display" :placeholder="$t('pleaseSelect')" v-model="recommendation.category" v-if="editing" /><span
                      v-if="!editing && recommendation != null && recommendation.category != null"
                      >{{ recommendation.category.display }}</span
                    >
                  </td>
                  <td>
                    <v-select :options="evidenceLevelList" :placeholder="$t('pleaseSelect')" label="code" v-model="recommendation.evidenceLevel" v-if="editing" /><span v-if="!editing && recommendation.evidenceLevel">{{
                      recommendation.evidenceLevel.code
                    }}</span>
                  </td>
                  <td>
                    <div class="arrows" v-if="editing">
                      <button type="button" class="btn btn-link vitu-icon" @click="onRemoveRecommendation(index)"><delete-icon /></button>
                      <button type="button" class="btn btn-link vitu-icon" @click="moveRecommendationUp(index)" v-if="index !== 0"><arrow-up-icon title="" /></button>
                      <button type="button" class="btn btn-link vitu-icon" @click="moveRecommendationDown(index)" v-if="index !== recommendations.length - 1"><arrow-down-icon title="" /></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td colspan="4">
                    <input type="text" class="form-control" :placeholder="$tc('comment', 1)" v-model="recommendation.other" v-if="editing" /><span v-if="!editing">{{ recommendation.other }}</span>
                  </td>
                </tr>
                <tr class="sub-heading">
                  <th></th>
                  <th>{{ $t("ingredient") }}</th>
                  <th colspan="3">{{ $t("worklist.reason") }}</th>
                  <th></th>
                </tr>
                <tr v-for="(reason, reasonIndex) in recommendation.reason" :key="reasonIndex">
                  <td></td>
                  <td>
                    <concept-select
                      class="multiple"
                      :fhirBaseUrl="fhirBaseUrl"
                      resourceName="ValueSet"
                      url="http://molit.eu/fhir/ValueSet/substances-protocols"
                      sort
                      multiple
                      :searchInputPlaceholder="$t('search')"
                      :token="token"
                      @error="handleError"
                      mapToConceptMap
                      conceptMapUrl="http://molit.eu/fhir/vitu/ConceptMap/protocols-to-atc"
                      :sortFunction="medicationSortFunction"
                      v-model="reason.medication"
                      v-if="editing"
                    >
                    </concept-select>
                    <span v-if="!editing && reason != null && reason.medication != null">{{
                      reason.medication.reduce((acc, c) => {
                        return acc + " " + c.display;
                      }, "")
                    }}</span>
                  </td>
                  <td colspan="3">
                    <v-select :options="annotatedVariants" multiple :placeholder="$t('pleaseSelect')" v-model="reason.genetics" v-if="editing" class="multiple">
                      <span slot="no-options">{{ $t("noMatchingOptions") }}</span>
                      <template #option="option">
                        {{ option.label }}
                      </template>
                      <template #selected-option="option">
                        {{ option.label }}
                      </template>
                    </v-select>
                    <div v-if="!editing && reason != null && reason.genetics != null">
                      <ul>
                        <li v-for="variant in reason.genetics" :key="variant.id">{{ variant.label }}</li>
                      </ul>
                    </div>
                    <input type="text" class="form-control mt-2" :placeholder="$t('otherReason')" v-if="editing" v-model="reason.other" />
                    <span v-if="!editing">{{ $t("otherReason") }}: {{ reason.other }}</span>
                  </td>
                  <td>
                    <button type="button" class="btn btn-link vitu-icon" @click="onRemoveReason(recommendation, reasonIndex)" v-if="editing"><delete-icon title="" /></button>
                  </td>
                </tr>
                <tr v-if="editing">
                  <td></td>
                  <td colspan="4" class="text-right">
                    <button type="button" class="btn btn-secondary" @click="onAddReason(recommendation)">{{ $t("addReason") }}</button>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="text-right" v-if="editing">
              <button type="button" @click="onAddRecommendation" class="btn btn-secondary">{{ $t("documentation.addTherapyRecommendation") }}</button>
            </div>
            <div class="form-group">
              <label for="note">{{ $t("furtherInformation") }}</label>
              <textarea class="form-control" id="note" rows="3" v-model="furtherInformation" v-if="editing"></textarea>
              <div class="mt-2" v-if="!editing" v-html="furtherInformationMarkdown"></div>
            </div>
          </fieldset>
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-primary" @click="save" v-if="editing">{{ $t("admin.save") }}</button>
          <button type="button" class="btn btn-secondary ml-2" @click="onCancel" v-if="editing">{{ $t("cancel") }}</button>
          <button type="button" class="btn btn-primary" @click="editing = !editing" v-if="!editing">{{ $t("edit") }}</button>
        </div>
      </form>

      <hr />
      <div>
        <div v-if="recommendationProtocol">
          <div class="d-flex justify-content-between">
            <a class="" @click="openDocument(recommendationProtocol)" href="javascript:void(null);"><download-icon title="" />{{ recommendationProtocol.description }}</a>
            <span class="btn-link delete-icon" @click="onDelete(recommendationProtocol)" style="cursor: pointer"><delete-icon title="" /></span>
          </div>
        </div>
        <div v-else>
          <div class="form-group form-row">
            <label class="col-md-3 col-form-label">{{ $t("recommendationProtocol") }}</label>
            <div class="col-md-9">
              <b-form-file :placeholder="$t('chooseFile')" accept="application/pdf" no-drop :browse-text="$t('browse')" v-model="newRecommendationProtocol"></b-form-file>
            </div>
          </div>
          <div class="page-footer">
            <div class="spacer"></div>
            <button type="button" class="btn btn-primary" @click="uploadRecommendationProtocol" :disabled="!newRecommendationProtocol || uploading">
              <span v-if="!uploading">{{ $t("uploadRecommendationProtocol") }}</span>
              <spinner v-else size="small" :speed="1.5" />
            </button>
          </div>
        </div>
      </div>

      <hr />
      <div class="page-footer">
        <div class="spacer"></div>
        <button type="button" class="btn btn-primary" @click="$emit('exportProtocol')">{{ $t("generateProtocol") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/config";

import DeleteIcon from "vue-material-design-icons/Delete";
import ArrowUpIcon from "vue-material-design-icons/ArrowUp";
import ArrowDownIcon from "vue-material-design-icons/ArrowDown";
import DownloadIcon from "vue-material-design-icons/Download";
import Spinner from "vue-simple-spinner";
// import { fetchByUrl, updateResource, submitResource } from "@molit/fhir-api";
import { getStringFromHumanName } from "@molit/fhir-util";
import { mapState } from "vuex";
import ConceptSelect from "@/components/ui/ConceptSelect";
import { moveInArray, markdownToHtml } from "@/util/util";
import fhirpath from "@/assets/js/fhirpath.min.js";
import { saveTherapyRecommendation } from "@/api/worklist-api";

import { submitResource, deleteResource } from "@molit/fhir-api";
import { authenticatedDownload } from "@/util/util";
import axios from "axios";
import { get } from "lodash";

export default {
  props: {
    instantOfTimeList: {
      type: Array,
      required: true
    },

    evidenceLevelList: {
      type: Array,
      required: true
    },

    recommendationCategoryList: {
      type: Array,
      required: true
    },

    recommendationList: {
      type: Object
    },

    variants: {
      type: Array,
      default() {
        return [];
      }
    },

    recommendationProtocol: {
      type: Object
    },
    patient: {
      type: Object,
      required: true
    },

    episodeOfCare: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      error: null,
      loading: false,
      editing: true,
      furtherInformation: "",
      fhirBaseUrl: config.FHIR_URL,
      newRecommendationProtocol: null,
      list: null,
      uploading: false,

      medicationSortFunction: (c1, c2) => {
        if (!c1.selectTitle || !c2.selectTitle) {
          return 0;
        }
        if (c1.selectTitle.includes("(") && !c2.selectTitle.includes("(")) {
          return 1;
        }
        if (!c1.selectTitle.includes("(") && c2.selectTitle.includes("(")) {
          return -1;
        }
        return c1.selectTitle.localeCompare(c2.selectTitle);
      },

      recommendations: [{ medication: [], reason: [{}] }]
    };
  },

  computed: {
    patientName() {
      if (this.patient != null) {
        return getStringFromHumanName(this.patient.name);
      }
      return "";
    },

    annotatedVariants() {
      if (this.variants != null) {
        return this.variants.map(variant => {
          return {
            label: this.getLabelForObservation(variant),
            id: variant.id
          };
        });
      }
      return [];
    },

    ...mapState({
      token: state => state.authentication.keycloak.token
    }),

    furtherInformationMarkdown() {
      return markdownToHtml(this.furtherInformation);
    }
  },

  methods: {
    onParticipantSelectButtonClicked() {
      this.showParticipantSelector = true;
    },

    onParticipantSelectorCancel() {
      this.showParticipantSelector = false;
    },

    onParticipantSelectorUpdate(practitioner) {
      const reference = this.getReferenceFromResource(practitioner);
      if (!this.participants.find(p => p.reference === reference)) {
        this.participants.push({
          display: getStringFromHumanName(practitioner.name),
          reference
        });
      }
      this.showParticipantSelector = false;
    },

    removeParticipant(participant) {
      const reference = participant.reference;
      this.participants = this.participants.filter(p => p.reference !== reference);
    },

    getReferenceFromResource(resource) {
      if (!resource || !resource.id || !resource.resourceType) {
        throw new Error("Resource not defined");
      }
      return `${config.FHIR_URL}/${resource.resourceType}/${resource.id}`;
    },

    getLabelForObservation(observation) {
      const gene = "Observation.component.where(code.coding.system='http://loinc.org' and code.coding.code='48018-6').valueCodeableConcept.coding.iif($this.display.exists(), $this.display, $this.code)";
      const pHGVS = "Observation.component.where(code.coding.system='http://loinc.org' and code.coding.code='48005-3').valueCodeableConcept.coding.iif($this.display.exists(), $this.display, $this.code)";
      const variantType = "Observation.component.where(code.coding.system='http://loinc.org' and code.coding.code='48019-4').valueCodeableConcept.coding.iif($this.display.exists(), $this.display, $this.code)";
      let label = fhirpath.evaluate(observation, gene) + " (" + fhirpath.evaluate(observation, variantType) + " " + fhirpath.evaluate(observation, pHGVS) + ")";
      return label.replace("copy_number_variation", "CNV");
    },

    async save() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }

      try {
        await saveTherapyRecommendation(this.fhirBaseUrl, this.recommendations, this.furtherInformation, this.patient, this.episodeOfCare, this.list, this.token);
        this.$emit("reload");
      } catch (e) {
        this.$emit("error", e);
      }
    },

    async uploadRecommendationProtocol() {
      this.uploading = true;
      try {
        await this.uploadFile(this.fhirBaseUrl, this.token, this.newRecommendationProtocol);
        this.newRecommendationProtocol = null;
        this.uploading = false;
        this.success = this.$t("uploadSuccessful");
        await this.$bvModal.msgBoxOk(this.$t("uploadSuccessful"), {
          title: this.$t("hint")
        });
        this.$emit("reload");
      } catch (e) {
        this.uploading = false;
        this.handleError(e, true);
      }
    },

    async onDelete(documentReference) {
      const answer = await this.$bvModal.msgBoxConfirm("Wirklich löschen?", {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("yes"),
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        this.newRecommendationProtocol = null;
        try {
          await this.deleteFile(this.fhirBaseUrl, this.token, documentReference);
          this.$emit("reload");
        } catch (e) {
          this.handleError(e, true);
        }
      }
    },

    async deleteFile(fhirBaseUrl, token, documentReference) {
      if (!documentReference || !documentReference.content) {
        return;
      }

      const content = documentReference.content;
      for (let i = 0; i < content.length; i++) {
        if (!content[i] || !content[i].attachment) {
          continue;
        }
        await this.deleteResourceByUrl(fhirBaseUrl, content[i].attachment.url, token);
      }

      return await deleteResource(fhirBaseUrl, documentReference, token);
    },

    async deleteResourceByUrl(fhirBaseUrl, url, token) {
      if (!fhirBaseUrl) {
        throw new Error("Resource was not deleted because the given fhirBaseUrl was null or undefined");
      }

      if (url === null || url === undefined) {
        throw new Error("URL must not be null or undefined");
      }

      const headers = {
        "Cache-Control": "no-cache"
      };

      if (token) {
        headers.Authorization = "Bearer " + token;
      }

      const options = {
        headers
      };

      return axios.delete(url, options);
    },

    async uploadBinary(fhirBaseUrl, token, file) {
      const binaryUrl = fhirBaseUrl + "/Binary";
      const options = {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": file.type
        }
      };
      if (token) {
        options.headers.Authorization = "Bearer " + token;
      }
      const binaryResponse = await axios.post(binaryUrl, file, options);
      return binaryResponse.headers.location;
    },

    async uploadFile(fhirBaseUrl, token, file) {
      if (!file) {
        console.error("No files selected.");
        return;
      }

      const content = [];

      const location = await this.uploadBinary(fhirBaseUrl, token, file);
      content.push({
        attachment: {
          contentType: file.type,
          url: location,
          title: file.name
        }
      });

      const documentReference = {
        status: "current",
        resourceType: "DocumentReference",
        meta: {
          profile: ["http://molit.eu/fhir/vitu/StructureDefinition/tumorboard-recommendation"]
        },
        type: {
          coding: [
            {
              system: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl",
              code: "C25197",
              display: "Recommendation"
            }
          ]
        },
        extension: [
          {
            url: "http://molit.eu/fhir/vitu/StructureDefinition/episode-of-care",
            valueReference: {
              reference: "EpisodeOfCare/" + this.episodeOfCare.id
            }
          }
        ],
        subject: {
          reference: "Patient/" + this.patient.id
        },
        date: new Date().toISOString(),
        description: file.name,
        content
      };

      return await submitResource(fhirBaseUrl, documentReference, token);
    },

    openDocument(documentReference) {
      const data = get(documentReference, "content[0].attachment.data");
      const location = get(documentReference, "content[0].attachment.url");
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = documentReference.description + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else if (location != null) {
        const title = get(documentReference, "content[0].attachment.title");
        authenticatedDownload(location, title, this.token);
      }
    },

    handleError(e) {
      this.$emit("error", e);
    },

    onAddRecommendation() {
      this.recommendations.push({ medication: [], reason: [{}] });
    },

    onRemoveRecommendation(index) {
      this.recommendations.splice(index, 1);
    },

    onAddReason(recommendation) {
      recommendation.reason.push({});
    },

    onRemoveReason(recommendation, reasonIndex) {
      recommendation.reason.splice(reasonIndex, 1);
    },

    moveRecommendationUp(index) {
      moveInArray(this.recommendations, index, index - 1);
    },

    moveRecommendationDown(index) {
      moveInArray(this.recommendations, index, index + 1);
    },

    onCancel() {
      this.$emit("reload");
    },

    initializeRecommendationsList() {
      if (this.recommendationList != null && this.recommendationList.entry != null) {
        this.editing = false;
        this.recommendations = this.recommendationList.entry.map(recommendation => JSON.parse(recommendation.item.display));
        this.list = this.recommendationList;
        if (this.list.note != null && this.list.note[0] != null) {
          this.furtherInformation = this.list.note[0].text;
        }
      }
    }
  },

  created() {
    this.initializeRecommendationsList();
  },

  watch: {
    recommendationList() {
      this.initializeRecommendationsList();
    }
  },

  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    ConceptSelect,
    DeleteIcon,
    DownloadIcon,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
table th {
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentation-wrapper {
  background: white;
  flex: 1;
}

.documentation {
  padding-bottom: 12rem;
}

.main {
  padding-top: 1rem;
}

fieldset {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

legend {
  font-size: 1.3rem;
}

.legend-button-panel {
  display: flex;
  justify-content: space-between;

  button {
    white-space: nowrap;
  }
}

.page-footer {
  border-top: 0;
}

.vitu-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}

.account-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}

.active-container {
  display: flex;
  align-items: center;
}

.btn-cancel {
  margin-right: 0.5rem;
}

.form-group.readonly {
  margin-bottom: 0;
}

.participant-add-panel {
  display: flex;
  justify-content: flex-end;
}

h6 {
  margin-top: 1rem;
}

.genetic-report-information {
  margin-bottom: 1.5rem;
}

.report {
  margin-bottom: 1rem;

  h6 {
    margin-bottom: 0;
    margin-top: 2vw;
  }
}

.therapy-recommentation-header {
  display: flex;
  justify-content: space-between;
}

.btn-link {
  padding: 0;
}

tr.sub-heading {
  th {
    font-weight: 400;
    font-style: italic;
  }
}

table tbody {
  border-top-color: rgba(0, 0, 0, 0.3);
}

table thead th {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}

.arrows {
  display: flex;
}
</style>
