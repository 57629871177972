<template>
  <div class="page-body">
    <spinner v-if="loading" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" />
    <form autocomplete="off" ref="form" v-on:submit.prevent="onSubmit">
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="title">{{ $t("name") }}*</label>
        <div class="col-md-9">
          <input type="text" required id="title" class="form-control" :placeholder="$t('conference.conference')" v-model="title" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="unlimited">{{ $t("permanent") }}</label>
        <div class="col-md-9">
          <div class="form-check radio-padding">
            <input class="form-check-input" type="checkbox" id="unlimited" v-model="unlimited" />
          </div>
        </div>
      </div>
      <div class="form-group row" v-if="!unlimited">
        <label for="date" class="col-md-3 col-form-label">{{ $t("dateAndTime") }}*</label>
        <div class="col-md-9">
          <div clasS="form-row">
            <div class="col-md-6">
              <input type="date" :min="currentDate" required id="date" class="form-control" :placeholder="$t('conference.conference')" v-model="date" />
            </div>
            <div class="col-md-6">
              <input type="time" required id="time" class="form-control" :placeholder="$t('conference.conference')" v-model="time" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label" for="color">{{ $t("color") }}</label>
        <div class="col-md-9">
          <div class="color-row">
            <div v-for="(btn, index) in colorButtons" :key="`color-btn-${index}`">
              <label class="color-btn" v-bind:class="{ active: btn.background === color }" :style="{ background: btn.background }">
                <input type="radio" name="colorButton" :id="btn.background" :value="btn.background" style="opacity: 0" v-model="color" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="form-group row">
        <label class="col-md-3 col-form-label" for="showVideosInitially">{{ $t("showVideosInitially") }}</label>
        <div class="col-md-9">
          <div class="form-check radio-padding">
            <input class="form-check-input" type="checkbox" id="showVideosInitially" v-model="showVideosInitially" />
          </div>
        </div>
      </div> -->
      <div class="button-flex">
        <button class="btn btn-secondary btn-cancel" type="button" v-on:click="closeEdit()">{{ $t("cancel") }}</button>
        <button class="btn btn-primary ml-2">{{ $t("admin.save") }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "@/config/config";
import Spinner from "vue-simple-spinner";
import { flatui1 } from "@/model/color-palettes";
import moment from "moment";

import { getRoom, deleteRoom, addRoom, updateRoom } from "@/api/video-api";
export default {
  data() {
    return {
      conference: null,
      loading: false,

      title: null,
      unlimited: false,
      date: null,
      time: "12:00",
      color: "#bdc3c7",
      showVideosInitially: false,
      roomId: null,
      tumorConferenceId: null,

      colorButtons: flatui1,

      currentDate: new Date().toISOString().split("T")[0]
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token
    }),

    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    isExisting() {
      return this.$route.params.id && this.$route.params.id !== "new";
    }
  },

  methods: {
    closeEdit(id) {
      this.$emit("close", id);
    },

    async onSubmit() {
      try {
        if (this.isExisting) {
          await this.updateRoom();
          this.closeEdit();
        } else {
          const response = await this.createRoom();
          this.$router.replace({ name: "conference-edit", params: { id: response.data.id } });
          this.closeEdit(response.data.id);
        }
      } catch (e) {
        this.$emit("error", e);
      }
    },

    async createRoom() {
      if (this.unlimited) {
        this.date = "2040-01-01";
      }
      return await addRoom(this.title, moment(this.date + "T" + this.time + ":00").format(), this.showVideosInitially, this.color, this.unlimited, this.token);
    },

    async updateRoom() {
      if (this.unlimited) {
        this.date = "2040-01-01";
      }
      await updateRoom(this.conference.janusId, this.roomId, this.tumorConferenceId, this.title, moment(this.date + "T" + this.time + ":00").format(), this.showVideosInitially, this.color, this.unlimited, this.token);
    },

    async deleteRoom(roomId) {
      this.deleteInProgress = true;
      try {
        await deleteRoom(roomId, this.token);
        this.deleteInProgress = false;
        this.reload();
      } catch (e) {
        this.$emit("error", e);
        this.deleteInProgress = false;
      }
    },

    async getRoom(id) {
      try {
        let response = await getRoom(this.token, id);
        if (response.status === 200 && response.data) {
          const room = response.data;
          this.conference = room;
          this.roomId = room.id;
          this.tumorConferenceId = room.tumorConference.id;
          this.title = room.tumorConference.description;
          this.color = room.videoRoomSettings.color;
          this.unlimited = room.videoRoomSettings.unlimited;
          this.showVideosInitially = room.videoRoomSettings.show_videos_initially;
          this.date = moment(room.tumorConference.date).format("yyyy-MM-DD");
          this.time = moment(room.tumorConference.date).format("HH:mm");
        }
      } catch (e) {
        this.$emit("error", e);
      }
    }
  },

  async created() {
    if (this.isExisting) {
      this.loading = true;
      await this.getRoom(this.$route.params.id);
      this.loading = false;
    }
  },

  components: {
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.button-flex {
  display: flex;
  justify-content: flex-end;
}
.color-row {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}
.color-btn {
  width: 24px;
  height: 24px;
  margin: 0.25rem;
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.active {
  opacity: 0.85;
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.2);
}
.radio-padding {
  padding-top: calc(0.375rem + 3px);
  padding-bottom: calc(0.375rem + 3px);
}
</style>
