export const palette = [
  { background: "#599968", color: "hsla(200, 0%, 0%, 0.7);" },
  { background: "#68b146", color: "hsla(200,0%,0%,.7)" },
  { background: "#1a3221", color: "hsla(0,0%,100%,.7)" },
  { background: "#150dbf", color: "hsla(0,0%,100%,.7)" },
  { background: "#7a99cc", color: "hsla(200,0%,0%,.7)" },
  { background: "#e6e6fa", color: "hsla(200, 0%, 0%, 0.7);" },
  { background: "#7e06dd", color: "hsla(273, 0%, 100%, 0.9)" },
  { background: "#2b0265", color: "hsla(0,0%,100%,.7)" },
  { background: "#808080", color: "hsla(0, 0%, 0%, 0.9)" },
  { background: "#3b454f", color: "hsla(0,0%,100%,.7)" },
  { background: "#f2e7ce", color: "hsla(200, 0%, 0%, 0.7)" },
  { background: "#a41b53", color: "hsla(349, 0%, 100%, 0.9)" },
  { background: "#ae3289", color: "hsla(318, 0%, 177%, 0.9)" },
  { background: "#bf0023", color: "hsla(349, 0%, 100%, 0.9)" },
  { background: "#b25a43", color: "hsla(11, 98%, 9%, 1)" },
  { background: "#eae51e", color: "hsla(200, 0%, 0%, 0.7)" },
  { background: "#d59021", color: "hsla(200, 0%, 0%, 0.7)" },
  { background: "#b69954", color: "hsla(200, 0%, 0%, 0.7)" }
];

export const flatui1 = [
  { background: "#1abc9c", color: "white" },
  { background: "#16a085", color: "white" },
  { background: "#2ecc71", color: "white" },
  { background: "#27ae60", color: "white" },
  { background: "#3498db", color: "white" },
  { background: "#2980b9", color: "white" },
  { background: "#9b59b6", color: "white" },
  { background: "#8e44ad", color: "white" },
  { background: "#34495e", color: "white" },
  { background: "#2c3e50", color: "white" },
  { background: "#f1c40f", color: "white" },
  { background: "#f39c12", color: "white" },
  { background: "#e67e22", color: "white" },
  { background: "#d35400", color: "white" },
  { background: "#e74c3c", color: "white" },
  { background: "#c0392b", color: "white" },
  { background: "#ecf0f1", color: "black" },
  { background: "#bdc3c7", color: "black" },
  { background: "#95a5a6", color: "black" },
  { background: "#7f8c8d", color: "black" }
];
