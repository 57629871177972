<template>
  <nav-sidebar top="60px" class="side-nav" :collapsible="true" :collapseText="$t('collapse')" contentClass="worklist-main">
    <nav-sidebar-header :title="$t('ownCases')" :route="{ name: 'own-cases' }">
      <template slot="icon">
        <div class="nav-header-icon">
          <bank-icon />
        </div>
      </template>
    </nav-sidebar-header>
    <nav-sidebar-item data-cy="sideNav-worklist" :title="$t('ownCases')" :route="{ name: 'own-clinical-cases' }">
      <template #icon>
        <bank-icon :title="$t('ownCases')" />
      </template>
    </nav-sidebar-item>
    <!-- <nav-sidebar-item data-cy="sideNav-patients" :title="$tc('worklist.patient', 2)" :route="{ name: 'patients' }">
      <template #icon>
        <account-icon :title="$tc('worklist.patient', 2)" />
      </template>
    </nav-sidebar-item>
    <nav-sidebar-item data-cy="sideNav-documentUpload" :title="$tc('upload', 1)" :route="{ name: 'temp-upload' }">
      <template #icon>
        <file-upload-icon :title="$tc('upload', 1)" />
      </template>
    </nav-sidebar-item> -->
  </nav-sidebar>
</template>

<script>
import NavSidebar from "@/components/ui/NavSidebar";
import NavSidebarHeader from "@/components/ui/NavSidebarHeader";
import NavSidebarItem from "@/components/ui/NavSidebarItem";
import AccountIcon from "vue-material-design-icons/Account";
import BankIcon from "vue-material-design-icons/Bank";
import ClipboardTextIcon from "vue-material-design-icons/ClipboardText";
import FileUploadIcon from "vue-material-design-icons/FileUpload";

export default {
  components: {
    AccountIcon,
    BankIcon,
    ClipboardTextIcon,
    FileUploadIcon,
    NavSidebar,
    NavSidebarHeader,
    NavSidebarItem
  }
};
</script>

<style lang="scss" scoped>
.side-nav {
  background: $vitu-background;
}

.nav-header-icon {
  background: white;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 0.1rem;
  color: rgba(0, 0, 0, 0.5);
  margin-left: -0.2rem;
}
</style>
