<template>
  <div class="cds">
    <cds-card v-for="card in cards" :key="card.summary" :card="JSON.stringify(card)" :locale="locale" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "@/config/config";
import { get } from "lodash";

import { getStudySuggestionsBasic } from "@/api/worklist-api";
export default {
  props: {
    patient: {
      type: Object
    },
    condition: {
      type: Object
    },
    subtype: {
      type: Object
    },
    uicc: {
      type: Object
    },
    variants: {
      type: Array
    }
  },

  data() {
    return {
      cards: []
    };
  },

  computed: {
    ...mapState({
      keycloak: state => state.authentication.keycloak,
      token: state => state.authentication.keycloak.token
    }),

    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    locale() {
      return this.$i18n.locale;
    }
  },

  methods: {
    async getStudySuggestions() {
      try {
        const auth = {
          username: config.CDS_USER,
          password: config.CDS_PASSWORD
        };
        this.cards = (await getStudySuggestionsBasic(config.CDS_URL, this.fhirBaseUrl, auth, this.patient, this.condition, this.subtype, this.uicc, this.variants)).data.cards;
        this.$emit("updateStudiesTotal", get(this.cards, "[0].suggestions.length", 0));
      } catch (e) {
        this.$emit("error", e);
      }
    }
  },

  mounted() {
    if (this.patient != null && this.condition != null) {
      this.getStudySuggestions();
    }
  }
};
</script>

<style lang="scss" scoped>
.cds {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
</style>
