<template>
  <div>
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
      :fluid="true"
    />
    <div class="container-fluid planner">
      <div class="row">
        <div class="col sidebar">
          <h6>{{ $t("planner.schedulableCases") }}</h6>
          <div class="form-group">
            <input type="text" class="form-control" :placeholder="$t('planner.searchCase')" v-model="searchTermCases" />
          </div>
          <p v-if="schedulableCases && schedulableCases.length === 0">{{ $t("planner.noSchedulableCases") }}</p>
          <spinner v-if="!schedulableCases && !warning" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" :message="$t('data.loading')" />
          <div class="list-group" v-if="schedulableCases">
            <div class="list-group-item flex-column align-items-start group-item entry" v-for="clinicalCase in schedulableCases" :key="clinicalCase.id">
              <div class="">
                <strong>{{ clinicalCase.patientName }}</strong>
              </div>
              <div class="text-muted">{{ clinicalCase.patientBirthDate ? $d(new Date(clinicalCase.patientBirthDate)) : "" }}</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="d-flex">
            <h6>{{ $t("planner.planner") }}</h6>
            <div class="spacer"></div>
            <!-- <div>
              <button class="btn btn-link pr-0" @click="exportConferences">{{ $t("export") }}</button>
            </div> -->
          </div>
          <div class="form-group">
            <input class="form-control" :placeholder="$t('planner.searchConference')" v-model="searchTermConference" />
          </div>

          <spinner v-if="!rooms" line-fg-color="#148898" line-bg-color="#99bfbf" size="medium" :speed="1.5" :message="$t('data.loading')" />
          <div v-if="rooms" class="conferences">
            <b-card no-body :class="['conference-card', 'new', { ghost: drag }]" @click="toConference(null, 'new')">
              <div class="headline">{{ $t("planner.scheduleNewConference") }}</div>
              <div class="spacer" />
              <div>
                <plus-icon class="icon" />
              </div>
            </b-card>
            <div no-body v-for="conference in paginatedRooms" :key="conference.id">
              <conference-card v-on:click="toConference(conference.id, 'details')" :conference="conference">
                <template slot="icon">
                  <div v-on:click="toConference(conference.id, 'edit')">
                    <pencil-icon />
                  </div>
                </template>
              </conference-card>
            </div>
          </div>
          <!-- <b-pagination v-if="filteredRooms" class="pagination" align="center" :total-rows="filteredRooms.length" :per-page="max" v-model="currentPage" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConferenceCard from "@/components/ConferenceCard";
import NotificationPanels from "@/components/ui/NotificationPanels";
import { fetchResources, mapFhirResponse } from "@molit/fhir-api";
import { getRooms } from "@/api/video-api";
import config from "../config/config";
import { addColonToISODateString } from "@/util/util";

import PencilIcon from "vue-material-design-icons/Pencil";
import PlusIcon from "vue-material-design-icons/Plus";
import Spinner from "vue-simple-spinner";
import { mapState } from "vuex";
import { get } from "lodash";
import notifications from "@/mixins/notifications";

export default {
  mixins: [notifications],

  data() {
    return {
      rooms: null,
      entries: null,
      statuses: [],

      currentConference: null,
      currentCase: null,

      addEndDate: false,

      dragOverConference: null,
      drag: false,
      error: null,
      showSuccess: false,

      searchTermConference: null,
      searchTermCases: "",

      searchParams: {
        code: "http://molit.eu/fhir/vitu/CodeSystem/vitu-task|mtb-task",
        status: "in-progress",
        _sort: "-authored-on",
        _include: ["Task:patient", "Task:task-input"]
      },

      currentPage: 1,
      max: 30,

      deleteInProgress: false
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token,
      subject: state => state.authentication.keycloak.subject,
      conferenceSettings: state => state.settings.conferenceSettings
    }),

    filteredRooms() {
      if (!this.rooms) {
        return this.rooms;
      }

      return this.rooms.filter(room => {
        if (room.tumorConference && room.tumorConference.id === -1) {
          return false;
        }
        if (room.tumorConference && room.tumorConference.date && new Date().getTime() > new Date(room.tumorConference.date).getTime() + 1000 * 60 * 60 * 144) {
          return false;
        }
        if (this.searchTermConference && room.tumorConference.description && !room.tumorConference.description.toLowerCase().includes(this.searchTermConference.toLowerCase())) {
          return false;
        }
        if (room.videoRoomSettings && room.videoRoomSettings && room.videoRoomSettings.unlimited === null) {
          return false;
        }
        return true;
      });
    },

    paginatedRooms() {
      if (!this.filteredRooms) {
        return [];
      }

      return this.filteredRooms;

      // return this.filteredRooms.slice((this.currentPage - 1) * this.max, this.currentPage * this.max);
    },

    item() {
      return this.currentCase;
    },

    schedulableCases() {
      if (!this.entries) {
        return null;
      } else {
        return this.entries
          .filter(entry => JSON.stringify(entry).toLowerCase().includes(this.searchTermCases.toLowerCase()))
          .map(entry => {
            return {
              id: entry.id,
              creationDate: entry.date_created,
              patientName: entry.patient.firstName + " " + entry.patient.lastName,
              patientBirthDate: entry.patient.dateOfBirth,
              caseId: entry.orbisCaseNo,
              diagnosis: entry.tumorDiagnose,
              status: entry.status,
              entry: entry
            };
          });
      }
    }
  },

  methods: {
    toConference(id, mode) {
      if (mode === "new") {
        this.$router.push({ name: "conference-edit", params: { id: "new" } });
      } else if (mode === "edit") {
        this.$router.push({ name: "conference-edit", params: { id } });
      } else {
        this.$router.push({ name: "conference-edit", params: { id } });
      }
    },

    async getRooms() {
      try {
        let response = await getRooms(this.token);
        if (response.status === 200 && response.data.entity) {
          this.rooms = response.data.entity.map(room => {
            if (room && room.tumorConference && room.tumorConference.date) {
              room.tumorConference.date = addColonToISODateString(room.tumorConference.date);
            }
            return {
              ...room,
              numberOfCases: 0
            };
          });
        } else {
          this.rooms = [];
        }
      } catch (e) {
        this.handleError(e);
      }
    },

    async fetchEntries() {
      try {
        const searchParams = {
          code: "http://molit.eu/fhir/vitu/CodeSystem/vitu-task|mtb-task",
          "business-status": "3-report-ready",
          _sort: "-authored-on",
          _include: ["Task:patient"]
        };

        const response = await fetchResources(config.FHIR_URL, "Task", searchParams, this.token);
        const resources = mapFhirResponse(response);

        this.entries = [];
        resources
          .filter(r => r.resourceType === "Task")
          .forEach(task => {
            const patient = resources.find(r => r.resourceType === "Patient" && r.id === task.for.reference.split("/")[1]);
            this.entries.push({
              id: task.id,
              patient: {
                dateOfBirth: patient.birthDate,
                firstName: get(patient, "name[0].given", []).join(", "),
                lastName: get(patient, "name[0].family", "")
              },
              task: task
            });
          });
      } catch (e) {
        this.handleError(e, true);
      }
    },

    async initialize() {
      try {
        this.fetchEntries();
        this.getRooms();
      } catch (e) {
        this.handleError(e);
      }
    }
  },

  mounted() {
    this.initialize();
  },

  components: {
    ConferenceCard,
    NotificationPanels,
    PencilIcon,
    PlusIcon,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.group-item {
  padding: 0.5rem;
  user-select: none;
}

.conference-card {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  cursor: pointer;
  height: 100%;

  .icon {
    color: map-get($theme-colors, "primary");
    font-size: 2.25rem;
  }
}

.headline {
  font-weight: bold;
  color: map-get($theme-colors, "primary");
}

.conferences {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
  grid-gap: 0.5rem;
}

.planner {
  padding-top: 15px;
  padding-bottom: 15px;
}

.list-group-item {
  .text-muted {
    font-size: 1rem;
  }
}

.pagination {
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .sidebar {
    max-width: 300px;
  }
}
</style>
