<template>
  <div>
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
    />
    <div class="container">
      <breadcrumps :breadcrumps="[{ name: $tc('upload', 2), route: { name: 'temp-upload' } }]" />
      <div class="page-header">
        <h5>Upload</h5>
      </div>
      <div class="page-body">
        <div>
          <div v-if="!uploading">
            <form autocomplete="off" ref="form" v-on:submit.prevent="onSubmit">
              <div class="form-group">
                <b-form-file v-model="newFiles" multiple required :placeholder="$t('chooseFiles')" accept="application/pdf" no-drop :browse-text="$t('browse')"></b-form-file>
              </div>
              <div class="information-icon-wrapper">
                <div><information-outline-icon class="information-icon" /></div>
                <div>
                  <strong>{{ $t("hint") }}:</strong> {{ $t("uploadHint") }}
                </div>
              </div>
              <div class="form-group">
                <textarea ref="comment" required class="form-control" :placeholder="$t('addComment') + '...'" rows="2" @input="mixin_autoResize_resize_by_event" v-model.trim="newComment"></textarea>
              </div>
              <div class="form-group text-right">
                <button class="btn btn-primary">Upload</button>
              </div>
            </form>
          </div>
          <spinner v-else />
        </div>

        <div v-if="isModerator">
          <div v-if="files">
            <h5>Files</h5>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">{{ $t("worklist.date") }}</th>
                  <th scope="col">{{ $t("admin.user") }}</th>
                  <th scope="col">{{ $tc("comment") }}</th>
                  <th scope="col">{{ $tc("file", 2) }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="file in files" :key="file.id">
                  <td>
                    <span v-if="file.date">{{ $d(new Date(file.date), "long") }}</span>
                  </td>
                  <td>
                    <span v-if="file.authenticator">{{ file.authenticator.display }}</span>
                  </td>
                  <td>{{ file.description }}</td>
                  <td>
                    <div v-for="content in file.content" :key="content.title">
                      <span v-if="content && content.attachment" class="btn-link" @click="download(content.attachment.url, content.attachment.title)">{{ content.attachment.title }}</span>
                    </div>
                  </td>
                  <td>
                    <span class="btn-link delete-icon" @click="onDelete(file)"><delete-icon title="" /></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <spinner v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumps from "@/components/ui/Breadcrumps";
import NotificationPanels from "@/components/ui/NotificationPanels";
import notifications from "@/mixins/notifications";
import config from "@/config/config";
import { mapState } from "vuex";
import { fetchResources, mapFhirResponse, submitResource, deleteResource } from "@molit/fhir-api";
import axios from "axios";
import { authenticatedDownload } from "@/util/util";
import autoResize from "@/mixins/auto-resize";
import Spinner from "@/components/ui/Spinner";
import DeleteIcon from "vue-material-design-icons/Delete";
import InformationOutlineIcon from "vue-material-design-icons/InformationOutline";
import roles from "@/model/roles";

export default {
  mixins: [notifications, autoResize],

  data() {
    return {
      files: null,
      newFiles: [],
      newComment: null,
      uploading: false
    };
  },

  computed: {
    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    fhirBaseUrlExternal() {
      return config.FHIR_URL_EXTERNAL;
    },

    ...mapState({
      token: state => state.authentication.keycloak.token,
      keycloak: state => state.authentication.keycloak
    }),

    fullUserName() {
      if (this.keycloak) {
        return this.keycloak.idTokenParsed.name;
      } else {
        return "";
      }
    },

    isModerator() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.MODERATOR);
      } else {
        return false;
      }
    }
  },

  methods: {
    async getFiles() {
      try {
        const params = {
          _summary: false,
          _count: 10000,
          _sort: "-date",
          type: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl|C53288"
        };
        this.files = mapFhirResponse(await fetchResources(this.fhirBaseUrl, "DocumentReference", params, this.token));
      } catch (e) {
        console.error(e);
        console.warn("Propably not in VPN.");
      }
    },

    async onSubmit() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return;
      }

      this.uploading = true;
      try {
        await this.uploadFiles(this.fhirBaseUrlExternal, this.token, this.newFiles, this.newComment, this.fullUserName);
        this.files = null;
        this.newComment = null;
        this.newFiles = [];
        this.uploading = false;
        this.success = this.$t("uploadSuccessful");
        this.$bvModal.msgBoxOk(this.$t("uploadSuccessful"), {
          title: this.$t("hint")
        });
        await this.getFiles();
      } catch (e) {
        this.uploading = false;
        this.handleError(e, true);
      }
    },

    async onDelete(documentReference) {
      const answer = await this.$bvModal.msgBoxConfirm("Wirklich löschen?", {
        title: this.$t("pleaseConfirm"),
        okTitle: this.$t("yes"),
        cancelTitle: this.$t("cancel")
      });

      if (answer) {
        this.files = null;
        try {
          await this.deleteFile(this.fhirBaseUrl, this.token, documentReference);
        } catch (e) {
          this.handleError(e, true);
        }
        this.getFiles();
      }
    },

    async deleteFile(fhirBaseUrl, token, documentReference) {
      if (!documentReference || !documentReference.content) {
        return;
      }

      const content = documentReference.content;
      for (let i = 0; i < content.length; i++) {
        if (!content[i] || !content[i].attachment) {
          continue;
        }
        await this.deleteResourceByUrl(fhirBaseUrl, content[i].attachment.url, token);
      }

      return await deleteResource(fhirBaseUrl, documentReference, token);
    },

    async deleteResourceByUrl(fhirBaseUrl, url, token) {
      if (!fhirBaseUrl) {
        throw new Error("Resource was not deleted because the given fhirBaseUrl was null or undefined");
      }

      if (url === null || url === undefined) {
        throw new Error("URL must not be null or undefined");
      }

      const headers = {
        "Cache-Control": "no-cache"
      };

      if (token) {
        headers.Authorization = "Bearer " + token;
      }

      const options = {
        headers
      };

      return axios.delete(url, options);
    },

    async uploadBinary(fhirBaseUrl, token, file) {
      const binaryUrl = fhirBaseUrl + "/Binary";
      const options = {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": file.type
        }
      };
      if (token) {
        options.headers.Authorization = "Bearer " + token;
      }
      const binaryResponse = await axios.post(binaryUrl, file, options);
      return binaryResponse.headers.location;
    },

    async uploadFiles(fhirBaseUrl, token, files, description, username) {
      if (!files || !files.length) {
        console.error("No files selected.");
        return;
      }

      const content = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const location = await this.uploadBinary(fhirBaseUrl, token, file);
        content.push({
          attachment: {
            contentType: file.type,
            url: location,
            title: file.name
          }
        });
      }

      const documentReference = {
        status: "current",
        resourceType: "DocumentReference",
        type: {
          coding: [
            {
              system: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl",
              code: "C53288",
              display: "Temporary"
            }
          ],
          text: "Temporary"
        },
        date: new Date().toISOString(),
        content,
        description,
        authenticator: {
          display: username
        }
      };

      return await submitResource(fhirBaseUrl, documentReference, token);
    },

    download(url, filename) {
      authenticatedDownload(url, filename, this.token);
    }
  },

  async created() {
    this.getFiles();
  },

  components: {
    Breadcrumps,
    DeleteIcon,
    InformationOutlineIcon,
    NotificationPanels,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.btn-link {
  cursor: pointer;
}

.information-icon-wrapper {
  display: flex;
  align-items: flex-start;
  background: lighten($vitu-background, 3.5%);
  padding: 0.4rem 0.4rem;
  margin-bottom: 1rem;
}

.information-icon {
  font-size: 18px;
  margin-right: 0.5rem;
}

.delete-icon {
  font-size: 1.2rem !important;
  color: deeppink;
}
</style>
